export const formatDateTime = (dateTime) => {
    if (!dateTime) {
      return "";
    }
    const date = new Date(dateTime);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
  };


  export const getFormattedAddressFromPlaces = (results) => {
    console.log(
      "🚀 ~ file:index.js ~ line 356 ~ getFormattedAddressFromPlaces ~ results",
      results
    );
    let city = "",
      state = "",
      zip = "",
      country = "",
      addressLine1 = "",
      addressLine2 = "",
      formattedAddress = "";
    for (let j = 0; j < results.length; j++) {
      const j = 0;
      for (var i = 0; i < results[j]?.address_components?.length; i++) {
        if (results[j].address_components[i].types[0] === "locality") {
          city = results[j].address_components[i].long_name;
        } else if (results[j].address_components[i].types[0] === "postal_code") {
          zip = results[j].address_components[i].long_name;
        } else if (
          results[j].address_components[i].types[0] ===
          "administrative_area_level_1"
        ) {
          state = results[j].address_components[i].long_name;
        } else if (results[j].address_components[i].types[0] === "country") {
          country = results[j].address_components[i].long_name;
        } else if (results[j].address_components[i].types[0] === "route") {
          addressLine1 = results[j].address_components[i].long_name;
        } else if (results[j].address_components[i].types[0] === "subpremise") {
          addressLine2 = results[j].address_components[i].long_name;
        }
      }
  
      if (results[0].formatted_address != null) {
        formattedAddress = results[0].formatted_address;
      }
  
      return {
        city,
        state,
        addressLine1,
        addressLine2,
        formattedAddress,
        zip,
        country,
      };
    }
  };


  export const getStateIdFromStateName = (stateListData, stateName) => {
    console.log(stateListData,stateName,'check state')
    const state = stateListData[1].find(
      ({ stateID_ids, stateName_chr }) => stateName_chr === stateName
    );
    if (!state) {
      return "";
    }
    return state.stateID_ids;
  };

  