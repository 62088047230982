import swal from "sweetalert";
import {
  PUT_POST_CARRIER,
  GET_CARRIER,
  IS_CARRIER_EXIST,
  UPLOAD_DOCUMENT,
  GET_DOCUMENT,
  GET_DOCUMENT_BLOB,
  POST_CARRIER_DOCS,
  SENDPUSHNOTIFICATION,
  userId,
  GET_TENANT_NAME_BY_ID,
  GET_TENANT_NAME_BY_TENANT_KEY,
  GET_DOCUMENT_FILE,
} from "../redux/types/endPoints";
import { token } from "./token";
import axios from "axios";
import { domainName } from "../redux/types/endPoints";

export const addEditCarrier = (data, callback, errorCallBack) => {
  const data1 = {
    ...data,
  };
  return async (dispatch) => {
    // dispatch({ type: "SET_SPINNER", payload: true })
    await axios
      .put(PUT_POST_CARRIER, data1, {
        headers: {
          Authorization: "",
          X_domain_name: data?.tenantName,
        },
      })
      .then((response) => {
        // dispatch({ type: "SET_SPINNER", payload: false })
        callback && callback(response);
      })
      .catch((error) => {
        // dispatch({ type: "SET_SPINNER", payload: false })
        errorCallBack && errorCallBack(error.response);
        swal({
          title: "Error",
          text: "Something Went Wrong",
          icon: "error",
          dangerMode: true,
        });
      });
  };
};

export const postCarrierDocuments = (
  data,
  authDetails,
  callback,
  errorCallBack
) => {
  console.log(data, "data555");
  return async (dispatch) => {
    dispatch({ type: "SET_SPINNER", payload: true });
    await axios
      .post(POST_CARRIER_DOCS, data, {
        headers: {
          Authorization: "",
          "Content-Type": "multipart/form-data",
          X_domain_name: authDetails?.tenantName,
        },
      })
      ?.then((res) => {
        if (res?.status === 200) {
          callback && callback(res);
          dispatch({ type: "SET_SPINNER", payload: false });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "SET_SPINNER", payload: false });
        swal({
          title: "Error",
          text: "Technical issue in Carrier Docs Uploading, Please try again after sometime",
          icon: "error",
          dangerMode: true,
        });
      });
  };
};

export const uploadAgreementDocument = (
  data,
  authDetails,
  callback,
  errorCallBack
) => {
  return async (dispatch) => {
    dispatch({ type: "SET_SPINNER", payload: true });
    await axios
      .post(UPLOAD_DOCUMENT, data, {
        headers: {
          Authorization: "",
          "Content-Type": "multipart/form-data",
          X_domain_name: authDetails?.tenantName,
        },
      })
      .then((response) => {
        callback && callback(response);
        console.log("upload document response", response);
        dispatch({ type: "SET_SPINNER", payload: false });
        swal({
          title: "Thankyou",
          text: "One of our team member will get back to you shortly.",
          icon: "success",
          dangerMode: false,
        });
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response);
        dispatch({ type: "SET_SPINNER", payload: false });
        swal({
          title: "Error",
          text: "Something Went Wrong",
          icon: "fail",
          dangerMode: true,
        });
      });
  };
};

export const sendPushNotificationCarrier =
  (params, authDetails, callback, errorCallBack) => async (dispatch) => {
    await axios
      .get(SENDPUSHNOTIFICATION, {
        params,
        headers: {
          Authorization: "",
          "X_domain_name": authDetails?.tenantName,
        },
      })
      .then((response) => {
        // const data = response?.data;
        callback && callback(response);
      })
      .catch((error) => {
        console.log("in catch block", error);
      });
  };

// export const sendPushNotifications = (data, callback, errorCallBack) => {
//   return async (dispatch) => {
//     // dispatch({ type: "SET_SPINNER", payload: true })
//     await axios.get(SENDPUSHNOTIFICATION, data, {
//       headers: {
//         'Authorization':token,
//         'x-domain-name': domainName
//       }
//     }).then((response) => {
//       callback && callback(response);
//     }).catch((error) => {
//         errorCallBack && errorCallBack(error.response);
//       });
//   };
// };

export const getCarrierDocuments = (params, callback) => async (dispatch) => {
  dispatch({ type: "SET_SPINNER", payload: true });
  await axios
    .get(GET_DOCUMENT, {
      params,
      headers: {
        Authorization: token,
        "x-domain-name": domainName,
      },
    })
    .then((response) => {
      const data = response?.data;
      callback && callback(data);
      dispatch({ type: "SET_SPINNER", payload: false });
    })
    .catch((error) => {
      console.log("in catch block");
      dispatch({ type: "SET_SPINNER", payload: false });
    });
};

export const getCarrierDocumentBlob =
  (params, callback) => async (dispatch) => {
    dispatch({ type: "SET_SPINNER", payload: true });
    await axios
      .get(GET_DOCUMENT_BLOB, {
        params,
        responseType: "blob",
        headers: {
          Authorization: token,
          "x-domain-name": domainName,
        },
      })
      .then((response) => {
        // const data = response?.data;
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        dispatch({ type: "SET_SPINNER", payload: false });
        callback && callback(blob);
      })
      .catch((error) => {
        console.log("in catch block");
        dispatch({ type: "SET_SPINNER", payload: false });
      });
  };

export const getCarrierDetails =
  (params, authDetails, callback) => async (dispatch) => {
    dispatch({ type: "SET_SPINNER", payload: true });
    await axios
      .get(GET_CARRIER, {
        params,
        headers: {
          Authorization: "",
          X_domain_name: authDetails?.tenantName,
        },
      })
      .then((response) => {
        const data = response?.data;
        dispatch({ type: "SET_SPINNER", payload: false });
        callback && callback(data);
      })
      .catch((error) => {
        dispatch({ type: "SET_SPINNER", payload: false });
        console.log("in catch block");
      });
  };

export const getTenantDetailsByTenantKey = (params, callback) => async (dispatch) => {
  dispatch({ type: "SET_SPINNER", payload: true });
  await axios
    .get(GET_TENANT_NAME_BY_TENANT_KEY, {
      params,
    })
    .then((response) => {
      const data = response?.data;
      dispatch({ type: "SET_SPINNER", payload: false });
      callback && callback(data);
    })
    .catch((error) => {
      dispatch({ type: "SET_SPINNER", payload: false });
      console.log("in catch block");
    });
};

export const getTenantDocsByUrl = (params, callback) => async (dispatch) => {
  dispatch({ type: "SET_SPINNER", payload: true });
  await axios
    .get(GET_DOCUMENT_FILE, {
      params,
    })
    .then((response) => {
      const data = response?.data;
      dispatch({ type: "SET_SPINNER", payload: false });
      callback && callback(data);
    })
    .catch((error) => {
      dispatch({ type: "SET_SPINNER", payload: false });
      console.log("in catch block");
    });
};

export const isCarrierExist = (params, callback) => async (dispatch) => {
  dispatch({ type: "SET_SPINNER", payload: true });
  await axios
    .get(IS_CARRIER_EXIST, {
      params,
      headers: {
        Authorization: token,
        "x-domain-name": domainName,
      },
    })
    .then((response) => {
      const data = response?.data;
      callback && callback(data);
      dispatch({ type: "SET_SPINNER", payload: false });
    })
    .catch((error) => {
      console.log("in catch block - is carrier exist");
      dispatch({ type: "SET_SPINNER", payload: false });
    });
};


export const formatUsPhone = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|91)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};