import { React, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import jasSign from "../../Components/navbar/NavbarPhoto/jassign.png";
import Load_Karma from "../../Components/navbar/NavbarPhoto/loadkarma_logo.jpg";
import Load_Konnect from "../../Components/navbar/NavbarPhoto/LoadKonnectLogo.jpg";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import {
  formatUsPhone,
  getCarrierDetails,
  getTenantDetailsById,
  getTenantDetailsByTenantKey,
  getTenantDocsByUrl,
  sendPushNotificationCarrier,
  sendPushNotifications,
} from "../../api/carrier";
import "./carrierAgreement.css";
import jsPDF from "jspdf";
import SignatureCanvas from "react-signature-canvas";
import { uploadAgreementDocument } from "../../api/carrier";
import { postSendEmail } from "../../redux/actions/emailServices";
import { infoEmailId, infoName, userId } from "../../redux/types/endPoints";

const CarrierAgreement = () => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [carrierData, setCarrierData] = useState({});
  const [signatureImage, setSignatureImage] = useState("");
  const [penColor, setPenColor] = useState("black");
  const [signSubmitted, setSignSubmitted] = useState(false);
  const [signWritten, setSignWritten] = useState(false);
  const [logoFile, setLogoFile] = useState();
  const spinner = useSelector((state) => state?.spinner?.spinnerState);
  const carrierAllDocuments = useSelector((state) => state?.carrierDocs);
  const [realTenantDetails, setRealTenantDetails] = useState("");
  let navigate = useNavigate();
  const { carrierId, tenantId: tenantName, userId, token } = useParams();
  const authDetails = { carrierId, tenantName, userId };

  const dispatch = useDispatch();
  const signPadRef = useRef({});
  console.log(carrierData, "carrierData42");

  const returnTo404Errorpage = () => {
    navigate(`/not-found`);
  };

  useEffect(() => {
    if (carrierId) {
      dispatch(
        getCarrierDetails(
          {
            Calling_UserID_chr: userId,
            CarrierID_lng: carrierId,
          },
          authDetails,
          (data) => {
            if (!data?.tblCarrier[0]) {
              returnTo404Errorpage();
            } else {
              // console.log(data?.tblCarrier[0],'data?.tblCarrier[0]')
              setCarrierData(data?.tblCarrier[0]);
            }
          }
        )
      );
    } else {
      returnTo404Errorpage();
    }
    dispatch(
      getTenantDetailsByTenantKey(
        { tenantKey_chr: tenantName },
        (tenantDetails) => {
          setRealTenantDetails(tenantDetails?.tblTenantDetail[0]);
          console.log(tenantDetails, "tenatDetails");
          // dispatch(
          //   getTenantDocsByUrl(
          //     {
          //       strDocumentUrl: `${tenantDetails?.tblTenantDetail[0]?.companyLogoUrl_chr}`,
          //     },
          //     (docs) => {
          //       let blob = new Blob([docs], {
          //         type: "image/jpg",
          //       });
          //       let logo = URL.createObjectURL(blob);
          //       // const imageElement = document.createElement("img");
          //       // imageElement.src = logo;
          //       console.log(blob, logo, "blob,logo");
          //       setLogoFile(logo);
          //     }
          //   )
          // );
        }
      )
    );
  }, []);

  console.log("carrierAggreementPage");

  const validateSignature = () => {
    if (!signWritten) {
      swal({
        title: "Signature not found",
        text: "Please Provide Signature",
        icon: "error",
        dangerMode: true,
      });
    }
    return signWritten;
  };

  const handleSubmit = () => {
    if (!validateSignature()) {
      return;
    }
    // saveSign()
    jsPdfGenerator();
  };

  var daylist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday ",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var monthlist = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var today = new Date();

  var day = today.getDay();

  var month = today.getMonth();

  // console.log(monthlist[month],'month')

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const currentDate = new Date();
  // Format the date
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const x = 150;
  const jsPdfGenerator = () => {
    var doc = new jsPDF("p", "pt");

    // doc.addImage(
    //   realTenantDetails?.companyLogoUrl_chr,
    //   "JPEG",
    //   265,
    //   x - 120,
    //   60,
    //   60
    // );

    // doc.setFont("Helvetica");

    // doc.text("BROKER - CARRIER AGREEMENT", 160, x - 30, {});

    // doc.setFontSize(9);

    
    // doc.text(
    //   `This Broker/Carrier Agreement is being entered into by and between ${
    //     realTenantDetails?.tenantName_chr
    //   } Inc a Nevada Based Corporation (hereinafter referred to as “BROKER”), and ${
    //     carrierData?.carrierName_chr
    //   }, (hereinafter referred to as “CARRIER”) as defined below, on this ${
    //     monthlist[month] + " " + dd + " " + yyyy
    //   } 
    //   `,
    //   40,
    //   x,
    //   {
    //     maxWidth: 510,
    //     align: "left",
    //   }
    // );


//////////////////////////////

const tenantName = realTenantDetails?.companyName_chr || 'Tenant Name';
const carrierName = carrierData?.carrierName_chr || 'Carrier Name';
const date = `${daylist[day]} day of ${monthlist[month]} ${dd} ${yyyy}`;


let yPosition = 40;
const pageHeight = doc.internal.pageSize.height;

const styles = {
  mainHeading: { fontSize: 12, fontStyle: 'bold', underline: true },
  subHeading: { fontSize: 12, fontStyle: 'bold' },
  subText: { fontSize: 12, textAlign: 'justify', maxWidth: 510 },
};

const addText = (text, x, y, style = {}) => {
  const splitText = doc.splitTextToSize(text, style.maxWidth || 200);
  const textHeight = splitText.length * (style.fontSize || 12) * 1.2; // Approximate height

  if (y + textHeight > pageHeight) {
    doc.addPage();
    y = 40; // Reset y position for new page
  }

  doc.setFontSize(style.fontSize || 12);
  doc.setFont('helvetica', style.fontStyle || 'normal');
  if (style.underline) {
    doc.text(splitText, x, y, { underline: true });
  } else {
    doc.text(splitText, x, y);
  }
  return y + textHeight;
};

// Add title
doc.setFontSize(16);
doc.setFont('helvetica', 'bold');
doc.text("BROKER/CARRIER AGREEMENT", doc.internal.pageSize.width / 2, yPosition, { align: 'center' });
yPosition += 40;

// Add introductory paragraph
doc.setFontSize(12);
doc.setFont('helvetica', 'normal');
const introText = `This Broker/Carrier Agreement is being entered into by and between ${tenantName} , a ${realTenantDetails?.brokerBasedOn_chr} Based Corporation (hereinafter referred to as “BROKER”), and ${carrierName}, (hereinafter referred to as “CARRIER”) as defined below, on this ${date}.`;
yPosition = addText(introText, 40, yPosition, { maxWidth: 510, fontSize: 12 });
yPosition += 20;

// Add Main Headings and Sub Headings with Content manually
yPosition = addText('I.', 40, yPosition, styles.mainHeading);
yPosition -=15
yPosition = addText('PARTIES', 60, yPosition, styles.mainHeading);
yPosition += 20;

yPosition = addText('A.', 40, yPosition, styles.subHeading);
yPosition -=15
const textA = `${tenantName} is the “Broker” as that term is defined under 49 U.S.C. § 13102(2) or any regulation, amendment or renumbered law by which the United States or any agency thereof defines a freight broker and any applicable federal or state regulations, statutes, decisional law or administrative law. BROKER will arrange for the freight tendered by a shipper to be transported by CARRIER under the means, manner, method, and terms selected by the shipper or CARRIER, but BROKER is not engaged in the business of and will not act as a “Carrier,” “Motor Carrier,” or “Freight Forwarder,” as those terms are defined under 49 U.S.C. § 13102, and BROKER is not engaged in the business of and will not act as a “Rail Carrier” as that term is defined under 49 U.S.C. § 11706.`;
yPosition = addText(textA, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('B.', 40, yPosition, styles.subHeading);
yPosition -=15
const textB = `${carrierName} is the “CARRIER,” and hereby agrees to transport freight identified by BROKER as requiring transportation services.`;
yPosition = addText(textB, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('C.', 40, yPosition, styles.subHeading);
yPosition -=15
const textC = `BROKER and CARRIER will sometimes be referred to collectively as “The Parties.”`;
yPosition = addText(textC, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('II.', 40, yPosition, styles.mainHeading);
yPosition -=15
yPosition = addText('RECITALS', 60, yPosition, styles.mainHeading);
yPosition += 20;

yPosition = addText('1.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Term-', 60, yPosition, styles.subHeading);
const text1 = `The term of this Agreement shall be one (1) year, commencing on the date listed above. If not cancelled by one of The Parties, the Agreement shall automatically renew itself for consecutive one year terms. The Agreement can be terminated at any time by either of The Parties with thirty (30) days written or electronic notice to the other party, provided all balances are settled, and the termination can be with or without cause.`;
yPosition = addText(text1, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('2.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Broker Requirements- ', 60, yPosition, styles.subHeading);
const text2 = `BROKER warrants that it is licensed to arrange for the transportation of freight pursuant to license number ${realTenantDetails?.brokerLicenseNumber_chr}, but that it does not transport freight, and that it will maintain such authority as required by all applicable federal and state laws and regulations throughout the course of this Agreement. BROKER also warrants that it will maintain a surety bond or trust fund agreement as required by the Federal Motor Carrier Safety Administration in the amount of $75,000.00 or in such amount as may be amended from time to time and furnish CARRIER with proof of same upon request.`;
yPosition = addText(text2, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('3.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Broker Obligations-', 60, yPosition, styles.subHeading);
yPosition = addText('Broker shall pay CARRIER for services rendered in an amount equal to the rates and charges agreed to as set forth on any Load Confirmation(s) that is issued and that supplements and amends this Agreement to the extent its terms conflict with those in this Agreement. This Agreement or the Load Confirmation also governs all assessorial services which may be required or performed.', 60, yPosition, styles.subText);
const text3 = `CARRIER shall not bill for any accessorial or other charge not approved in this Agreement or in any Load Confirmation(s). Rates may be amended orally but must be confirmed in writing within five working days of the modification in order to remain binding between the PARTIES. As a condition precedent to payment, CARRIER must submit proof of delivery with its invoices, and the invoices must reflect that CARRIER delivered the freight to its final destination.`;
yPosition = addText(text3, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('a.', 80, yPosition, styles.subHeading);
yPosition -=15
const text3a = `BROKER agrees to arrange for the transportation of a shipper’s freight with CARRIER pursuant to the terms of this Agreement, and to comply with all federal, state, and local laws and regulations pertaining to the brokerage services covered by this Agreement.`;
yPosition = addText(text3a, 100, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('b.', 80, yPosition, styles.subHeading);
yPosition -=15
const text3b = `The Parties agree that BROKER’S responsibilities under this Agreement are limited to arranging for the transportation of a shipper’s freight with CARRIER, and not actually performing the transportation services, possessing the freight, or controlling the means or methods of the transportation.`;
yPosition = addText(text3b, 100, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('4.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Carrier Obligations -', 60, yPosition, styles.subHeading);
const text4 = `CARRIER warrants that at all times during this Agreement it will act as a “motor carrier,” as that term is defined under 49 U.S.C. § 13102 and any applicable federal or state regulations, statutes, decisional law or administrative law. CARRIER further warrants that at all times during this Agreement it will remain licensed and authorized by the Federal Motor Carrier Safety Administration to provide interstate transportation services or that it provides only intrastate service and complies with all applicable state registration requirements, and warrants that it will maintain insurance or otherwise demonstrate financial responsibility in accordance with all applicable federal and state regulations.`;
yPosition = addText(text4, 60, yPosition, styles.subText);
yPosition += 20;
const text4Para1 = `CARRIER is solely responsible for the operation of the equipment, actions of the driver, any other persons associated with the operation of the equipment,transportation of freight, securement or any other aspect of actions of a motor carrier as that term is defined by law. CARRIER is solely responsible for the safety and operation of the equipment, and the actions of all drivers and other persons or entities responsible for the transportation of freight. Nothing in this Agreement abrogates the responsibility of the CARRIER to operate safely and in accordance with all law and good accepted best practices of a motor carrier.`;
yPosition = addText(text4Para1, 60, yPosition, styles.subText);
yPosition += 15;
const text4Para2 = `CARRIER represents that it is in compliance with and shall maintain, during the terms of this Agreement, compliance with all applicable federal, state and local laws relating to the provision of its services.`;
yPosition = addText(text4Para2, 60, yPosition, styles.subText);
yPosition += 15;
const text4Para3 = `CARRIER will notify BROKER immediately if its federal Operating Authority is revoked, suspended or rendered inactive for any reason; and/or if it is sold, or if there is a change in control of ownership, and/or any insurance required hereunder is threatened to be or is terminated, cancelled (whether by an insurer or surety provider by CARRIER, or by any person or entity), suspended, or revoked for any reason.`;
yPosition = addText(text4Para3, 60, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('a.', 80, yPosition, styles.subHeading);
yPosition -=15
const text4a = `CARRIER agrees it will not have a U.S. DOT safety rating or evaluation of unsatisfactory or conditional. Any change in CARRIER’S safety rating requires immediate written notification to BROKER. CARRIER may not have an unsatisfactory or conditional rating under any rating system. If CARRIER’S rating becomes conditional or unsatisfactory, CARRIER is no longer authorized as a CARRIER under this Agreement.`;
yPosition = addText(text4a, 100, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('b.', 80, yPosition, styles.subHeading);
yPosition -=15
const text4b = `Upon reasonable demand, CARRIER shall provide to BROKER copies of its DOT Operating Authority, Policy of Insurance, including all endorsements, Certificate of Insurance, surety, and financial responsibility.`;
yPosition = addText(text4b, 100, yPosition, styles.subText);
yPosition += 40;

yPosition = addText('5.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Food Protocol -', 60, yPosition, styles.subHeading);

yPosition = addText('All equipment provided for the transportation of food or food grade products will comply with the requirements of The Sanitary Food Transportation Act, or, to the extent that CARRIER performs services hereunder within, or to or from Canada.', 60, yPosition, styles.subText);
const text5 = `The Food and Drug Acts and any/all other applicable statutes and regulations, including, but not limited to the Ontario Food Safety and Quality Act, 2001, or any other jurisdiction's equivalent, and none of the equipment so provided has been or will be used for the transportation of any waste of any kind, garbage, hazardous materials, poisons, pesticides, herbicides, or any other commodity that might adulterate or contaminate food, food products or cosmetics.`;

yPosition = addText(text5, 60, yPosition, styles.subText);
yPosition += 15;
const text5P2 = `Where a seal is placed on a trailer by consignor, shipper, CARRIER or other party, CARRIER is responsible to maintain the seal intact until removed by an authorized employee of consignee upon delivery. CARRIER is liable for any and all claims, losses, or liabilities arising from or as a result of any unauthorized removal of seal, broken seal, missing seal, tampered seal, or mismatched seal number. CARRIER is solely responsible for ensuring that cargo is maintained according to any requirements stated on the bill of lading or load confirmation`;
yPosition = addText(text5P2, 60, yPosition, styles.subText);
yPosition += 15;
const textP3 = `CARRIER must ensure that all personnel transporting or handling freight subject to the Food Safety Modernization Act of 2011 and its implementing regulations (collectively the “Act”), receive training required by the Act. BROKER will transmit to CARRIER, on the Load Confirmation or separately by email, the shipper’s or consignee’s protocols and requirements for transporting food shipments subject to the Act. CARRIER must strictly comply with all such protocols and requirements. CARRIER’S failure to comply with such protocols and requirements will permit the consignor, consignee, or broker to declare any freight transported on a shipment on which noncompliance occurred to be rejected and a total loss.`;
yPosition = addText(textP3, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('6.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Shipper-Broker Relationship-', 60, yPosition, styles.subHeading);
const text6 = `The Parties agree that BROKER at all times will be acting as an independent contractor, and not an employee, agent, or principal of a shipper.`;
yPosition = addText(text6, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('7.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Broker-Carrier Relationship-', 60, yPosition, styles.subHeading);
const text7 = `CARRIER agrees and acknowledges that as the motor carrier transporting a shipper’s freight pursuant to this Agreement, CARRIER is an independent contractor, and not an employee, agent or principal of BROKER. CARRIER further agrees and acknowledges that its employees and agents, including the driver or drivers transporting freight, are not the employees or agents of BROKER, and that BROKER does not control or have the right to control the CARRIER, its employees, agents, drivers, or any person or entity associated with the CARRIER.`;
yPosition = addText(text7, 60, yPosition, styles.subText);
yPosition += 15;
const text7P2 = `CARRIER must give priority to compliance with all laws and regulations and must not interpret any provision of this Agreement or request or communication from any employee or agent of BROKER, shipper, consignor, or BROKER’s customer(s) to authorize or encourage, directly or by implication, CARRIER to deviate from any law or regulation applicable to CARRIER’s operations as a motor carrier. BROKER will not coerce CARRIER, and any directions or instructions given by BROKER to CARRIER for the transportation of the freight shall be for information and convenience only, and CARRIER retains full control of the details of transportation of freight assigned to it under this Agreement. BROKER will not impose fines on CARRIER unless BROKER is instructed to do so by the shipper.`;
yPosition = addText(text7P2, 60, yPosition, styles.subText);
yPosition += 20;
// const text7P3 = `CARRIER must ensure that all personnel transporting or handling freight subject to the Food Safety Modernization Act of 2011 and its implementing regulations (collectively the “Act”), receive training required by the Act. BROKER will transmit to CARRIER, on the Load Confirmation or separately by email, the shipper’s or consignee’s protocols and requirements for transporting food shipments subject to the Act. CARRIER must strictly comply with all such protocols and requirements. CARRIER’S failure to comply with such protocols and requirements will permit the consignor, consignee, or broker to declare any freight transported on a shipment on which noncompliance occurred to be rejected and a total loss.`;
// yPosition = addText(textP3, 60, yPosition, styles.subText);
// yPosition += 15;

yPosition = addText('8.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('No Broker Liability-', 60, yPosition, styles.subHeading);
yPosition = addText('Liability- CARRIER agrees and acknowledges that BROKER will not be liable to a shipper for any act or omission of the CARRIER or any of its “employees” which transport a shipper’s freight, as the term “employee” is defined under 49 C.F.R. §390.5 or for any of Carrier’s Agents, Principals, Assigns or Subcontractors.', 60, yPosition, styles.subText);
const text8 = `CARRIER thus agrees and acknowledges to indemnify and hold harmless BROKER for any cargo loss or damage, or for delay in the delivery of a shipper’s freight, or for any actual or consequential damages resulting therefrom.`;
yPosition = addText(text8, 60, yPosition, styles.subText);
yPosition += 15;
const text8P2 = `CARRIER shall defend, indemnify and hold BROKER and its shipper customer harmless from any claims, actions or damages, arising out of its performance under this Agreement, including cargo loss and damage, theft, delay, damage to property, and personal injury or death, and BROKER shall defend, indemnify, and hold CARRIER harmless from any claims, actions, or damages, including cargo loss and damage, theft, delay, property damage, bodily injury or death, arising out of its performance hereunder. Neither Party shall be liable to the other for any claims, actions or damages due to the negligence, culpable conduct or intentional act of the other Party, or the shipper. The obligation to defend shall include all costs of defense as they accrue.`;
yPosition = addText(text8P2, 60, yPosition, styles.subText);
yPosition += 15;
const text8P3 = `Except for CARRIERS’S liability under Paragraph 10, unless otherwise agreed in writing, and regardless of whether the Parties’ insurance as referred to in this Agreement above is valid or provides coverage, the Parties’ indemnity obligations shall not exceed the monetary insurance limits referred to in the paragraph above.`;
yPosition = addText(text8P3, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('9.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('No Broker Control-', 60, yPosition, styles.subHeading);
const text9 = `The Parties agree that BROKER will not assert any control nor have any right to exercise control over a shipper’s freight, including, but not limited to, taking possession of a shipper’s freight, and BROKER shall not direct or control the routes taken by CARRIER in the transportation of a shipper’s freight.`;
yPosition = addText(text9, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('10.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Carrier Liability-', 70, yPosition, styles.subHeading);
const text10 = `CARRIER hereby assumes the liability of a motor carrier as provided in §14706 of Title 49 of the United States Code (the Carmack Amendment), and all claims for loss, damage and/or salvage will be handled and processed in accordance with 49 C.F.R. Part 370.`;
yPosition = addText(text10, 70, yPosition, styles.subText);
yPosition += 20;


yPosition = addText('11.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Bills of Lading-', 70, yPosition, styles.subHeading);
yPosition = addText('a.', 70, yPosition, styles.subHeading);
yPosition -=15
const text11a = `For each shipment tendered to CARRIER, CARRIER will provide to the shipper a standard bill of lading that is in accordance with 49 C.F.R. §373, listing the consignor and consignee, the origins and destinations, the number of packages, the description of the freight, and the weight, volume or measurement of the freight. The Parties agree that BROKER will not be a party to the bill of lading.`;
yPosition = addText(text11a, 90, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('b.', 70, yPosition, styles.subHeading);
yPosition -=15
const text11b = `CARRIER acknowledges that BROKER should not be listed on the bill of lading and that if BROKER is listed on the Bill of Lading as the carrier this will occur for the convenience of the shipper only and CARRIER at all times is the actual carrier of goods and BROKER’S role is limited to arranging for transportation. In the event BROKER’S name is listed on the bill of lading, shipping manifest or other similar document, as the carrier, CARRIER shall cross-out or otherwise remove BROKER’S name and enter CARRIER’S name as applicable.`;
yPosition = addText(text11b, 90, yPosition, styles.subText);
yPosition = addText('c.', 70, yPosition+10, styles.subHeading);
yPosition -=15
yPosition = addText('CARRIER understands that re-brokering and double brokering may be prohibited by law and will not re-broker, assign or interline the shipments hereunder without the express written consent of BROKER prior to theshipment being tendered to any other CARRIER. If CARRIER breaches this provision, BROKER shall have the right of paying the monies it owes CARRIER directly to the delivering carrier, in lieu of payment to CARRIER, and BROKER shall thereby be released from any further obligation to pay CARRIER. ', 90, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('Upon Broker’s payment to delivering carrier, CARRIER shall not be released from any liability to BROKER under this Agreement. IN ADDITION TO THE INDEMNITY OBLIGATION IN PARAGRAPH 7, CARRIER WILL BE LIABLE FOR CONSEQUENTIAL DAMAGES FOR VIOLATION OF THIS PARAGRAPH.', 90, yPosition, styles.subText);

const text11c = ` 

i.   The Parties agree that the shipment of freight will move under the terms and conditions listed in the bill of lading, except where inconsistent with the terms of this Agreement.

ii.  CARRIER agrees to list itself on the bill of lading as the party in possession and control of the freight.

iii.   The terms and conditions of the bill of lading shall not operate to alter or modify the terms of this Agreement between CARRIER and BROKER.

iv.   CARRIER shall issue a bill of lading in compliance with 49 U.S.C. §80101 et seq., 49 C.F.R. §373.101 (and any amendments thereto), for the property it receives for transportation under this Agreement. Unless otherwise agreed in writing, CARRIER shall become responsible/liable for the freight when it takes/receives possession thereof, and the trailer(s) is loaded, regardless of whether a bill of lading has been issued, and/or delivered to CARRIER, and which responsibility/liability shall continue until delivery of the shipment to the consignee and the consignee signs the bill of lading or delivery receipt. Any terms of the bill of lading (including but not limited to payment terms, released rates or released value) inconsistent with the terms of this Agreement shall be ineffective. Failure to issue a bill of lading or sign a bill of lading acknowledging receipt of the cargo by CARRIER shall not affect the liability of CARRIER. Said Bills of Lading are intended by the Parties to be Bills of Lading, as that term is interpreted under the Carmack Amendment and applicablelaw and not merely as “delivery receipts”, “freight receipts” or any similar term.`;
yPosition = addText(text11c, 80, yPosition, styles.subText);

yPosition = addText('12.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Non-Solicitation of Shippers-', 70, yPosition, styles.subHeading);
const text12 = `CARRIER agrees that it will not directly or indirectly conduct business with any shipper whose freight was transported pursuant to this Agreement for a period of two (2) years beginning with the last day such service was performed for that shipper. The Parties agree that a breach of this provision shall entitle BROKER, as reasonable liquidated damages and not as apenalty, to the full amount of commissions and/or compensation under the terms set forth in this Agreement that would have been due to BROKER had it arranged for the movement of said freight.`;
yPosition = addText(text12, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('13.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Assignment/Modifications of Agreement-', 70, yPosition, styles.subHeading);
const text13 = `Neither CARRIER or BROKER may assign or transfer any rights under this Agreement, in whole or in part, without the prior written consent of the other party. Further, neither CARRIER nor BROKER may amend or modify the terms of this Agreement without the prior written consent of an expressly authorized official of the other party. For BROKER, only a company official with the title of Vice President or higher is authorized to agree to amendments to this Agreement. Any amendments or modifications to this Agreement not agreed to by both CARRIER and BROKER shall be null and void.`;
yPosition = addText(text13, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('14.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Insurance -', 70, yPosition, styles.subHeading);
const text14 = `CARRIER shall furnish BROKER with Certificate(s) of Insurance; financial responsibility or insurance policies providing thirty (30) days advance written notice of cancellation or termination; and unless otherwise agreed, subject to the following minimum limits:`;
yPosition = addText(text14, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('A.', 80, yPosition, styles.subHeading);
yPosition -=15
const text14a = `general liability $1,000,000;`;
yPosition = addText(text14a, 100, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('B.', 80, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Commercial auto or commercial motor vehicle insurance $1,000,000, ($5,000,000 if transporting hazardous materials including environmental damages due to release or discharge of hazardous substances; hazmat carriers must have endorsement CA9948,', 100, yPosition, styles.subText);
yPosition += 20;
const text14b = `sudden and accidental pollution coverage, and this endorsement must be shown on the Certificate of Insurance provided to BROKER);`;
yPosition = addText(text14b, 100, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('C.', 80, yPosition, styles.subHeading);
yPosition -=15
const text14c = `cargo damage/loss, $100,000 This coverage must be All Risk Broad Form Motor Truck Cargo Legal Liability Coverage. The coverage provided under the policy shall have no exclusions or restrictions of any type that would foreseeably preclude coverage relating to cargo claims including, but not limited to, exclusions of unattended or unattached trailers, unattended or unlocked vehicles, theft, or for any commodities transported under this Agreement, refrigeration breakdown or lack of refrigerator fuel. Furthermore, if the commodity being hauled is refrigerated, refrigeration breakdown coverage will be provided and the CARRIER will honor and abide by the servicing requirements set forth in the insurance policy or endorsement. Furthermore, if the commodity being hauled is on a flatbed or similar open conveyance, that there be no exclusion for wetness, rust, corrosion or moisture.`;
yPosition = addText(text14c, 100, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('D.', 80, yPosition, styles.subHeading);
yPosition -=15
const text14d = `commercial auto or commercial motor vehicle insurance $1,000,000, ($5,000,000 if transporting hazardous materials including environmental damages due to release or discharge of hazardous substances; hazmat carriers must have endorsement CA9948, sudden and accidental pollution coverage, and this endorsement must be shown on the Certificate of Insurance provided to BROKER);`;
yPosition = addText(text14d, 100, yPosition, styles.subText);
yPosition += 20;
const text14P1=`Except for the higher coverage limits which may be specified above, the insurance policies and financial responsibility shall comply with minimum requirements of the Federal Motor Carrier Safety Administration and any other applicable regulatory state agency. Nothing in this Agreement shall be construed to avoid CARRIER’S liability due to any exclusion or deductible of any insurance policy or to limit CARRIER’S liability for contribution and/or indemnification and defense of the BROKER.`
yPosition = addText(text14P1, 60, yPosition, styles.subText);
const text14P2=`Coverage must be written with a CARRIER rated A- or better as rated by AM Best Company. When an intrastate policy is issued, BROKER must be named as an additional insured.`
yPosition = addText(text14P2, 60, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('15.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Miscellaneous :', 70, yPosition, styles.subHeading);
yPosition = addText('A.', 70, yPosition+20, styles.subText);
yPosition -=15
yPosition = addText('Non-Exclusive Agreement:', 90, yPosition, styles.subHeading);
const text15a = `CARRIER and BROKER acknowledge and agree that this contract does not bind the respective Parties to exclusive services to each other. Either party may enter into similar agreements with other carriers, brokers, or`;
yPosition = addText(text15a, 90, yPosition, styles.subText);
yPosition += 20;
yPosition = addText('B.', 70, yPosition, styles.subText);
yPosition -=15
yPosition = addText('Waiver of Provisions:', 90, yPosition, styles.subHeading);
const text15b = `i.   Failure of either Party to enforce a breach of waiver of any provision or term of this Agreement shall not be deemed to constitute a waiver of any subsequent failure or breach, and shall not affect or limit the right of either Party to there afterenforce such a term or provision.

ii.   This Agreement is for specified services pursuant to 49 U.S.C.§14101(b). To the extent that terms and conditions herein are inconsistent with Part (b), Subtitle IV, of Title 49 U.S.C. (ICC Termination Act of 1995), the Parties expressly waive any or all rights and remedies they may have under the Act.`;
yPosition = addText(text15b, 90, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('16.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Severability-', 70, yPosition, styles.subHeading);
yPosition = addText('If any portion or provision of this Agreement is determined by a court of competent jurisdiction to be invalid or unenforceable,', 70, yPosition, styles.subText);
const text16 = `The Parties agree that said portion or provision of the Agreement shall be severable, and that the remaining provisions of the Agreement shall continue in full force and effect.`;
yPosition = addText(text16, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('17.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Notices-', 70, yPosition, styles.subHeading);
const text17 = `Any and all written or electronic notices required or permitted to be given under this Agreement shall be addressed as follows:`;
yPosition = addText(text17, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('18.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Force Majeure-', 70, yPosition, styles.subHeading);
const text18 = `In the event that fire, flood, other natural disaster, war, embargo, riot, or civil disobedience prevents the performance of either BROKER or CARRIER’S obligations under this agreement, that party shall not be liable to the other party for such failure to perform.`;
yPosition = addText(text12, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('19.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Choice of Law and Venue-', 70, yPosition, styles.subHeading);
const text19 = `All issues concerning the construction, interpretation, validity, and enforceability of this Agreement, and any other dispute arising out of this Agreement, whether in a court of law or in alternative dispute resolution, shall be governed by and construed and enforced in accordance with the laws of the State of ${realTenantDetails?.brokerBasedOn_chr}, including the applicable statutes of limitations under ${realTenantDetails?.brokerBasedOn_chr} law, without giving effect to any choice of law provision applying the laws of another jurisdiction.`;
yPosition = addText(text19, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('20.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Indemnification:', 70, yPosition, styles.subHeading);
const text20 = `CARRIER will indemnify and hold harmless BROKER, its employees, officers, directors, agents, principals and assigns from any liability, settlements, judgments, verdicts, attorney fees or expense or any nature whatsoever arising out of any claims, demands or suits against BROKER which in any way relate to a claim of BROKER’s liability or culpability for the actions of CARRIER, including negligent or improper hiring or retention of the CARRIER, its employees (statutory or otherwise) agents, principals, officers, directors, assigns or anyone acting by or for CARRIER, for any aspect of the transportation of freight, public liability, personal injury, bodily injury, emotional or mental distress, wrongful death, loss of consortium, cargo liability or any claim or cause of action recognized by any state, municipality, county or any jurisdiction, Administrative Agency, or the Government of the United States. CARRIER agrees to have insurance to cover its indemnification obligations under this section, but CARRIER’s indemnification obligations are not capped by the amount of any available insurance.`;
yPosition = addText(text20, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('21.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Entire Agreement-', 70, yPosition, styles.subHeading);
const text21 = `This Agreement, including all appendices and addenda, constitutes the entire agreement intended by and between The Parties and supersedes all prior agreements, representations, warranties, and understandings, whether oral or in writing.`;
yPosition = addText(text21, 70, yPosition, styles.subText);
yPosition += 20;

yPosition = addText('22.', 40, yPosition, styles.subHeading);
yPosition -=15
yPosition = addText('Modification of Agreement -', 70, yPosition, styles.subHeading);
const text22 = `This Agreement and Exhibit A et seq. attached may not be amended, except by mutual written agreement, or the procedures set forth above.`;
yPosition = addText(text22, 70, yPosition, styles.subText);
yPosition += 20;



    let signature = new Image();
    signature.src = signatureImage;

    doc.addPage();

const finalCloser=`IN WITNESS WHEREOF, The Parties have caused this Agreement to be executed on the effective date listed above in their respective names by their fully authorized representatives below:`
yPosition = addText(finalCloser, 40, 40, { maxWidth: 510, fontSize: 12 });
// yPosition += 30;

    doc.text(
      60,
      120,
      `
    (BROKER)

    Authorized Signature:

    Printed Name: ${realTenantDetails?.printedName_chr}

    Title: Broker

    Company Address: ${realTenantDetails?.companyAddress_chr}

    Phone: ${formatUsPhone(realTenantDetails?.phoneNumber_chr)}

    E-Mail: ${realTenantDetails?.emailAddress_chr}`,
      {
        maxWidth: 200,
      }
    );

    doc.addImage(
      realTenantDetails?.authorizedSignatureUrl_chr,
      "PNG",
      189,
      145,
      90,
      30
    );

    doc.text(
      300,
      120,
      `
    (CARRIER)

    Authorized Signature:

    Printed Name: ${carrierData?.primaryContactName_chr}

    Title: Carrier

    Company Address: ${
      carrierData?.addressLine1_chr +
      ", " +
      carrierData?.city_chr +
      ", " +
      carrierData?.countryName_chr
    }

    Phone: ${carrierData?.phoneNumber_chr ? carrierData?.phoneNumber_chr : ""}

    E-Mail: ${
      carrierData?.primaryContactEmail_chr
        ? carrierData?.primaryContactEmail_chr
        : ""
    }`,
      {
        maxWidth: 200,
      }
    );

    doc.setDrawColor(0, 0, 0);
    doc.rect(40, 105, 530, 260);

    doc.addImage(signature, "PNG", 420,140, 150, 30);

    // doc.addPage();
    yPosition = addText(`CARRIER DETAILS:`, 50, 465, styles.subHeading);

    // yPosition+=20;
    
    yPosition = addText(`
    MC# / DOT - ${
      carrierData?.mC_FF_MXNumber_chr
        ? carrierData?.mC_FF_MXNumber_chr
        : carrierData?.usdotNumber_chr
      // carrierData?.mC_FF_MXNumber_chr || carrierData?.usdotNumber_chr
    }

    EIN - ${carrierData?.einNumber_chr ? carrierData?.einNumber_chr : ""}

    Company Name / DBA - ${
      carrierData?.carrierName_chr ? carrierData?.carrierName_chr : ""
    }

    First Name - ${
      carrierData?.primaryContactName_chr?.split(" ")[0]
        ? carrierData?.primaryContactName_chr?.split(" ")[0]
        : ""
    }

    Last Name - ${
      carrierData?.primaryContactName_chr?.split(" ")[1]
        ? carrierData?.primaryContactName_chr?.split(" ")[1]
        : ""
    }

    Phone Number- ${
      carrierData?.phoneNumber_chr ? carrierData?.phoneNumber_chr : ""
    }
    
    Address - ${
      carrierData?.addressLine1_chr + ", " + carrierData?.addressLine2_chr
    }

    City- ${carrierData?.city_chr ? carrierData?.city_chr : ""}

    State- ${carrierData?.stateName_chr ? carrierData?.stateName_chr : ""}

    Zip Code- ${
      carrierData?.zipPostalCode_chr ? carrierData?.zipPostalCode_chr : ""
    }

    Email Address - ${
      carrierData?.primaryContactEmail_chr
        ? carrierData?.primaryContactEmail_chr
        : ""
    }
    ${"  "}`, 40, yPosition, styles.subText);

    yPosition+=20

    yPosition = addText(`INSURANCE COMPANY DETAILS:`, 50, yPosition+20, styles.subHeading);
    yPosition = addText(`
    Insurance Company - ${
      carrierData?.cargoInsurance_chr ? carrierData?.cargoInsurance_chr : ""
    }

    Contact Name - ${
      carrierData?.insuranceContactName_chr
        ? carrierData?.insuranceContactName_chr
        : ""
    }

    Phone Number – ${
      carrierData?.insurancePhoneNumber_chr
        ? carrierData?.insurancePhoneNumber_chr
        : ""
    }

    Insurance Email – ${
      carrierData?.insuranceEmail_chr ? carrierData?.insuranceEmail_chr : ""
    }
    ${"  "}`, 40, yPosition, styles.subText);

    if( carrierData.factoringCoName_chr !== null){
      yPosition = addText(`FACTORING DETAILS:`, 50, yPosition+20, styles.subHeading);
      yPosition = addText(`
          Name- ${
            carrierData?.factoringCoName_chr ? carrierData?.factoringCoName_chr : ""
          }
      
          Factoring Contact Name - ${
            carrierData?.factoringContactName_chr
              ? carrierData?.factoringContactName_chr
              : ""
          }
      
          Factoring Phone Number - ${
            carrierData?.factoringCoPhoneNumber_chr
              ? carrierData?.factoringCoPhoneNumber_chr
              : ""
        }`, 20, yPosition, styles.subText)
     }else{
      yPosition = addText(`REMITTANCE DETAILS:`, 50, yPosition+20, styles.subHeading);
      yPosition-=20
      yPosition = addText(`
        ${
          `
          ${
            carrierData.financialInstitutionName_chr !== null
              ? `
            Financial Institution Name - ${
              carrierData?.financialInstitutionName_chr
                ? carrierData?.financialInstitutionName_chr
                : ""
            }
      
            Account Number  - ${
              carrierData?.accountNumber_chr ? carrierData?.accountNumber_chr : ""
            }
      
            Routing number - ${
              carrierData?.routingNumber_chr ? carrierData?.routingNumber_chr : ""
            }
            `
              : `  
          Check Payable to - ${
              carrierData?.checksPayableTo_chr ? carrierData?.checksPayableTo_chr : ""
            }
      
          Mailing address - ${
              carrierData?.mailingAddress_chr ? carrierData?.mailingAddress_chr : ""
            }
            `
          }`
        }`, 13, yPosition, styles.subText);
     }
// yPosition = addText(`
//   ${
//     carrierData.factoringCoName_chr !== null
//       ? `FACTORING DETAILS:

//     Name- ${
//       carrierData?.factoringCoName_chr ? carrierData?.factoringCoName_chr : ""
//     }

//     Factoring Contact Name - ${
//       carrierData?.factoringContactName_chr
//         ? carrierData?.factoringContactName_chr
//         : ""
//     }

//     Factoring Phone Number - ${
//       carrierData?.factoringCoPhoneNumber_chr
//         ? carrierData?.factoringCoPhoneNumber_chr
//         : ""
//     }`
//       : `  REMITTANCE DETAILS:
//     ${
//       carrierData.financialInstitutionName_chr !== null
//         ? `
//       Financial Institution Name - ${
//         carrierData?.financialInstitutionName_chr
//           ? carrierData?.financialInstitutionName_chr
//           : ""
//       }

//       Account Number  - ${
//         carrierData?.accountNumber_chr ? carrierData?.accountNumber_chr : ""
//       }

//       Routing number - ${
//         carrierData?.routingNumber_chr ? carrierData?.routingNumber_chr : ""
//       }
//       `
//         : `  
//     Check Payable to - ${
//         carrierData?.checksPayableTo_chr ? carrierData?.checksPayableTo_chr : ""
//       }

//     Mailing address - ${
//         carrierData?.mailingAddress_chr ? carrierData?.mailingAddress_chr : ""
//       }
//       `
//     }`
//   }`, 40, yPosition, styles.subText);
if(carrierData.factoringCoName_chr !== null){
  yPosition+=20
}

 yPosition = addText(`
    Printed Name: ${
      carrierData?.primaryContactName_chr
        ? carrierData?.primaryContactName_chr
        : ""
    }

    Date Signed: ${formattedDate}
    ${"  "}

    Signature: `, 40, yPosition-20, styles.subText);

    // doc.addImage(signature, "PNG", 400,yPosition-10, 150, 30);
  

    if (carrierData.factoringCoName_chr !== null) {
      doc.addImage(signature, "PNG", 100, yPosition-50, 180, 50);
    } else {
      if (carrierData.financialInstitutionName_chr !== null) {
        doc.addImage(signature, "PNG", 100, yPosition-50, 180, 50);
      } else {
        doc.addImage(signature, "PNG", 100, yPosition-50, 180, 50);
      }
    }

    const blobPdf = new Blob([doc.output("blob")], { type: "application/pdf" });
    const file = new File([blobPdf], "agreement.pdf", {
      type: "application/pdf",
    });

    const formData = new FormData();

    formData.append("CarrierID_lng", carrierData?.carrierID_ids);
    formData.append("CarrierDocumentName_chr", "broker-carrier-aggrement");
    formData.append("BrokerCarrierAgreementFile", file);
    formData.append("Calling_UserID_chr", userId);

    dispatch(
      uploadAgreementDocument(formData, authDetails, (data) => {
        if (data.status === 200) {
          dispatch(
            sendPushNotificationCarrier(
              {
                MessageBody: `Hey ${carrierData?.agentName_chr}, Carrier no. ${carrierData?.carrierID_ids} (${carrierData?.carrierName_chr}) has filled final agreement document`,
                carrierId: carrierData?.carrierID_ids,
                platform: "web",
                title: "Carrier Onboarding",
                notificationType: "notification",
                link: `/carrier/${carrierData?.carrierID_ids}/docs`,
              },
              authDetails
            )
          );
          navigate(`/${tenantName}/thankyou`);
        } else {
          swal({
            title: "Error",
            text: "Technical issue in Carrier Agreement Uploading, Please try again after sometime",
            icon: "error",
            dangerMode: true,
          });
        }
      })
    );

    // console.log(blobPdf,'blobPdf')
    // console.log(file,'file')

    const postEmailFormData = new FormData();

    postEmailFormData.append("stFrom", infoEmailId);
    postEmailFormData.append("strTo", carrierData?.primaryContactEmail_chr);

    postEmailFormData.append(
      "strBody",
      `Dear <b>${carrierData.carrierName_chr}</b>, 
    <br/> 
    Thank you for choosing to partner with us as a carrier.
    <br/>  
    We appreciate your interest in joining our logistics network.
    <br/>  
    <br/> 
    Please find attached the document for your review.
    <br/>  
    <br/> 
    Best regards,
    <br/>  
  
    ${realTenantDetails?.companyName_chr}
    `
    );
    postEmailFormData.append("strToName", carrierData?.primaryContactName_chr);
    postEmailFormData.append("strFromName", infoName);
    postEmailFormData.append("strReplyTo", [`${carrierData?.agentEmail_chr}`]);
    postEmailFormData.append(
      "strSubject",
      `Welcome to Our Carrier Network,  ${carrierData?.carrierName_chr}`
    );
    postEmailFormData.append("attachment", file);

    dispatch(postSendEmail(postEmailFormData, authDetails));

    // sendMailToAgentWithAttachments(file);

    // doc.save('generated.pdf')
  };

  // let w9File = new File([carrierAllDocuments.w9Form], "W9 File.pdf",  {type: 'application/pdf'});

  // let insuranceForm = new File([carrierAllDocuments.insuranceForm], "Insurance Form.pdf",  {type: 'application/pdf'});

  // let carrierAuthForm = new File([carrierAllDocuments.carrierAuthForm], "Carrier Auth Form.pdf",  {type: 'application/pdf'});

  // let NOA = new File([carrierAllDocuments.NOA], "Notice of Assignment.pdf",  {type: 'application/pdf'});

  // let voidCheck = new File([carrierAllDocuments.voidCheck], "Void Check.pdf",);

  //   const sendMailToAgentWithAttachments = (file) => {
  //     const postEmailtoAgentFormData1 = new FormData();

  //   postEmailtoAgentFormData1.append('stFrom','info@dialsight.com')
  //   postEmailtoAgentFormData1.append('strTo',carrierData?.agentEmail_chr )
  //   postEmailtoAgentFormData1.append('strBody',`Hi ${carrierData.agentName_chr}, you have received the signed carrier packet from the Carrier (${carrierData?.carrierName_chr}), ${carrierData?.mC_FF_MXNumber_chr}`)
  //   postEmailtoAgentFormData1.append('strToName',carrierData?.agentName_chr)
  //   postEmailtoAgentFormData1.append('strFromName',"LoadKarma")
  //   postEmailtoAgentFormData1.append('strSubject',"Carrier Onboarding")
  //   postEmailtoAgentFormData1.append('attachment',file)
  //   postEmailtoAgentFormData1.append('attachment',w9File)
  //   postEmailtoAgentFormData1.append('attachment',insuranceForm)
  //   postEmailtoAgentFormData1.append('attachment',carrierAuthForm)

  //   if(carrierAllDocuments.NOA == null){
  //     console.log('NOA document not found')
  //   }else{
  //     postEmailtoAgentFormData1.append('attachment',NOA)
  //   }

  //   if(!carrierAllDocuments?.voidCheck){
  //     console.log('Void Check not found')
  //   }else{
  //     postEmailtoAgentFormData1.append('attachment',voidCheck)
  //   }
  //   dispatch(postSendEmail(postEmailtoAgentFormData1));
  // }

  // console.log(id, "id");
  // console.log(carrierData, "carrierData");

  const clearSign = () => {
    signPadRef.current.clear();
    setSignWritten(false);
  };

  let signData = "";
  const saveSign = () => {
    signData = signPadRef?.current?.toDataURL();
    if (!signData) {
      return;
    }
    setSignatureImage(signData);
    // signPadRef?.current.clear();
    // console.log(signData, "5558");
    setSignSubmitted(true);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "termscheckbox") {
      setIsTermsAccepted((state) => !state);
    }
  };

  const signingStart = () => {
    setSignWritten(true);
  };

  //  console.log(carrierData.factoringCoName_chr,'carrierData.factoringCoName_chr value here');

  return (
    <>
      {spinner ? (
        <div id="backdrop">
          <div class="text-center loading">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
      ) : null}
      <div class="container brokerAgreement ">
        <style>
          {`
          .main-heading {
            font-weight: bold;
            text-decoration: underline;
            display: inline-block;
            margin-right: 10px;
          }
          .heading-container {
            display: flex;
            align-items: center;
            gap:25px
          }
          .sub-heading-container {
            display: flex;
            align-items: flex-start;
            margin-left: 45px;
            gap:25px
          }
           .sub-heading {
            font-weight: bold;
            margin-right: 10px;
          }
          .sub-text {
            text-align: justify;
            flex: 1;
          }
            .nested-heading {
           font-weight: bold;
            margin-right: 10px;
          }
          .nested-text {
            margin-left: 40px;
            text-align: justify;
          }
            .horizontal-line {
            width: 300%;  /* Adjust the width as needed */
            height: 1px;  /* Adjust the height as needed */
            background-color: black;  /* Change the color as needed */
            margin: 20px 0;  /* Add some vertical space around the line */
        }
        `}
        </style>
        <div class="text-center">
          <b>BROKER/CARRIER AGREEMENT</b>
        </div>
        <br></br>
        <p class="text-justify">
          <b>{"     "} This Broker/Carrier Agreement</b> is being entered into
          by and between <u>{realTenantDetails?.companyName_chr}</u>  a {realTenantDetails?.brokerBasedOn_chr} 
          {" "}Based Corporation (hereinafter referred to as <b>“BROKER”</b>), and{" "}
          <u>{carrierData?.carrierName_chr}</u>, (hereinafter referred to as{" "}
          <b>“CARRIER”</b>) as defined below, on this <b>{daylist[day]}</b> day
          of <b>{monthlist[month] + " " + dd + " " + yyyy}</b>
          {/* , between <u>{realTenantDetails?.tenantName_chr} Inc</u> (hereinafter
          referred to as "BROKER”) and <u>{carrierData?.carrierName_chr}</u>{" "}
          (hereinafter referred to as "CARRIER"). */}
        </p>{" "}
        <br></br>
        <div className="heading-container">
          <p className="main-heading">I.</p>{" "}
          <p className="main-heading">PARTIES</p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">A.</p>
          <p className="sub-text">
            {realTenantDetails?.companyName_chr}  is the “Broker” as that term is defined under 49
            U.S.C. § 13102(2) or any regulation, amendment or renumbered law by
            which the United States or any agency thereof defines a freight
            broker and any applicable federal or state regulations, statutes,
            decisional law or administrative law. BROKER will arrange for the
            freight tendered by a shipper to be transported by CARRIER under the
            means, manner, method, and terms selected by the shipper or CARRIER,
            but BROKER is not engaged in the business of and will not act as a
            “Carrier,” “Motor Carrier,” or “Freight Forwarder,” as those terms
            are defined under 49 U.S.C. § 13102, and BROKER is not engaged in
            the business of and will not act as a “Rail Carrier” as that term is
            defined under 49 U.S.C. § 11706.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">B.</p>
          <p className="sub-text">
            <u>{carrierData?.carrierName_chr}</u> is the “CARRIER,” and hereby
            agrees to transport freight identified by BROKER as requiring
            transportation services.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">C.</p>
          <p className="sub-text">
            BROKER and CARRIER will sometimes be referred to collectively as
            “The Parties.”
          </p>
        </div>
        <div className="heading-container">
          <p className="main-heading">II.</p>{" "}
          <p className="main-heading">RECITALS</p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">1.</p>
          <p className="sub-text">
            <b>Term- </b>The term of this Agreement shall be one (1) year,
            commencing on the date listed above. If not cancelled by one of The
            Parties, the Agreement shall automatically renew itself for
            consecutive one year terms. The Agreement can be terminated at any
            time by either of The Parties with thirty (30) days written or
            electronic notice to the other party, provided all balances are
            settled, and the termination can be with or without cause.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">2.</p>
          <p className="sub-text">
            <b>Broker Requirements-</b> BROKER warrants that it is licensed to
            arrange for the transportation of freight pursuant to license number
            {" "}{realTenantDetails?.brokerLicenseNumber_chr}, but that it does not transport freight, and that it
            will maintain such authority as required by all applicable federal
            and state laws and regulations throughout the course of this
            Agreement. BROKER also warrants that it will maintain a surety bond
            or trust fund agreement as required by the Federal Motor Carrier
            Safety Administration in the amount of $75,000.00 or in such amount
            as may be amended from time to time and furnish CARRIER with proof
            of same upon request.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">3.</p>
          <p className="sub-text">
            <b>Broker Obligations-</b> Broker shall pay CARRIER for services
            rendered in an amount equal to the rates and charges agreed to as
            set forth on any Load Confirmation(s) that is issued and that
            supplements and amends this Agreement to the extent its terms
            conflict with those in this Agreement. This Agreement or the Load
            Confirmation also governs all assessorial services which may be
            required or performed. CARRIER shall not bill for any accessorial or
            other charge not approved in this Agreement or in any Load
            Confirmation(s). Rates may be amended orally but must be confirmed
            in writing within five working days of the modification in order to
            remain binding between the PARTIES. As a condition precedent to
            payment, CARRIER must submit proof of delivery with its invoices,
            and the invoices must reflect that CARRIER delivered the freight to
            its final destination.
            <br />
            <br />
            <div className="sub-heading-container">
              <p className="sub-heading">a.</p>
              <p className="sub-text">
                BROKER agrees to arrange for the transportation of a shipper’s
                freight with CARRIER pursuant to the terms of this Agreement,
                and to comply with all federal, state, and local laws and
                regulations pertaining to the brokerage services covered by this
                Agreement.
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">b.</p>
              <p className="sub-text">
                The Parties agree that BROKER’S responsibilities under this
                Agreement are limited to arranging for the transportation of a
                shipper’s freight with CARRIER, and not actually performing the
                transportation services, possessing the freight, or controlling
                the means or methods of the transportation.
              </p>
            </div>
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">4.</p>
          <p className="sub-text">
            <b>Carrier Obligations - </b> CARRIER warrants that at all times
            during this Agreement it will act as a “motor carrier,” as that term
            is defined under 49 U.S.C. § 13102 and any applicable federal or
            state regulations, statutes, decisional law or administrative law.
            CARRIER further warrants that at all times during this Agreement it
            will remain licensed and authorized by the Federal Motor Carrier
            Safety Administration to provide interstate transportation services
            or that it provides only intrastate service and complies with all
            applicable state registration requirements, and warrants that it
            will maintain insurance or otherwise demonstrate financial
            responsibility in accordance with all applicable federal and state
            regulations.
            <br />
            <br /> CARRIER is solely responsible for the operation of the
            equipment, actions of the driver, any other persons associated with
            the operation of the equipment, transportation of freight,
            securement or any other aspect of actions of a motor carrier as that
            term is defined by law. CARRIER is solely responsible for the safety
            and operation of the equipment, and the actions of all drivers and
            other persons or entities responsible for the transportation of
            freight. Nothing in this Agreement abrogates the responsibility of
            the CARRIER to operate safely and in accordance with all law and
            good accepted best practices of a motor carrier.
            <br />
            <br /> CARRIER represents that it is in compliance with and shall
            maintain, during the terms of this Agreement, compliance with all
            applicable federal, state and local laws relating to the provision
            of its services.
            <br />
            <br /> CARRIER will notify BROKER immediately if its federal
            Operating Authority is revoked, suspended or rendered inactive for
            any reason; and/or if it is sold, or if there is a change in control
            of ownership, and/or any insurance required hereunder is threatened
            to be or is terminated, cancelled (whether by an insurer or surety
            provider by CARRIER, or by any person or entity), suspended, or
            revoked for any reason.
            <br />
            <br />
            <div className="sub-heading-container">
              <p className="sub-heading">a.</p>
              <p className="sub-text">
                CARRIER agrees it will not have a U.S. DOT safety rating or
                evaluation of unsatisfactory or conditional. Any change in
                CARRIER’S safety rating requires immediate written notification
                to BROKER. CARRIER may not have an unsatisfactory or conditional
                rating under any rating system. If CARRIER’S rating becomes
                conditional or unsatisfactory, CARRIER is no longer authorized
                as a CARRIER under this Agreement.
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">b.</p>
              <p className="sub-text">
                Upon reasonable demand, CARRIER shall provide to BROKER copies
                of its DOT Operating Authority, Policy of Insurance, including
                all endorsements, Certificate of Insurance, surety, and
                financial responsibility.
              </p>
            </div>
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">5.</p>
          <p className="sub-text">
            <b>Food Protocols - </b> All equipment provided for the
            transportation of food or food grade products will comply with the
            requirements of The Sanitary Food Transportation Act, or, to the
            extent that CARRIER performs services hereunder within, or to or
            from Canada, the Food and Drug Acts and any/all other applicable
            statutes and regulations, including, but not limited to the Ontario
            Food Safety and Quality Act, 2001, or any other jurisdiction's
            equivalent, and none of the equipment so provided has been or will
            be used for the transportation of any waste of any kind, garbage,
            hazardous materials, poisons, pesticides, herbicides, or any other
            commodity that might adulterate or contaminate food, food products
            or cosmetics.
            <br />
            <br />
            Where a seal is placed on a trailer by consignor, shipper, CARRIER
            or other party, CARRIER is responsible to maintain the seal intact
            until removed by an authorized employee of consignee upon delivery.
            CARRIER is liable for any and all claims, losses, or liabilities
            arising from or as a result of any unauthorized removal of seal,
            broken seal, missing seal, tampered seal, or mismatched seal number.
            CARRIER is solely responsible for ensuring that cargo is maintained
            according to any requirements stated on the bill of lading or load
            confirmation
            <br />
            <br /> CARRIER must ensure that all personnel transporting or
            handling freight subject to the Food Safety Modernization Act of
            2011 and its implementing regulations (collectively the “Act”),
            receive training required by the Act. BROKER will transmit to
            CARRIER, on the Load Confirmation or separately by email, the
            shipper’s or consignee’s protocols and requirements for transporting
            food shipments subject to the Act. CARRIER must strictly comply with
            all such protocols and requirements. CARRIER’S failure to comply
            with such protocols and requirements will permit the consignor,
            consignee, or broker to declare any freight transported on a
            shipment on which noncompliance occurred to be rejected and a total
            loss.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">6.</p>
          <p className="sub-text">
            <b>Shipper-Broker Relationship-</b>The Parties agree that BROKER at
            all times will be acting as an independent contractor, and not an
            employee, agent, or principal of a shipper.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">7.</p>
          <p className="sub-text">
            <b>Broker-Carrier Relationship-</b> CARRIER agrees and acknowledges
            that as the motor carrier transporting a shipper’s freight pursuant
            to this Agreement, CARRIER is an independent contractor, and not an
            employee, agent or principal of BROKER. CARRIER further agrees and
            acknowledges that its employees and agents, including the driver or
            drivers transporting freight, are not the employees or agents of
            BROKER, and that BROKER does not control or have the right to
            control the CARRIER, its employees, agents, drivers, or any person
            or entity associated with the CARRIER.
            <br />
            <br />
            CARRIER must give priority to compliance with all laws and
            regulations and must not interpret any provision of this Agreement
            or request or communication from any employee or agent of BROKER,
            shipper, consignor, or BROKER’s customer(s) to authorize or
            encourage, directly or by implication, CARRIER to deviate from any
            law or regulation applicable to CARRIER’s operations as a motor
            carrier. BROKER will not coerce CARRIER, and any directions or
            instructions given by BROKER to CARRIER for the transportation of
            the freight shall be for information and convenience only, and
            CARRIER retains full control of the details of transportation of
            freight assigned to it under this Agreement. BROKER will not impose
            fines on CARRIER unless BROKER is instructed to do so by the
            shipper.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">8.</p>
          <p className="sub-text">
            <b>No Broker Liability- </b> CARRIER agrees and acknowledges that
            BROKER will not be liable to a shipper for any act or omission of
            the CARRIER or any of its “employees” which transport a shipper’s
            freight, as the term “employee” is defined under 49 C.F.R. §390.5 or
            for any of Carrier’s Agents, Principals, Assigns or Subcontractors.
            CARRIER thus agrees and acknowledges to indemnify and hold harmless
            BROKER for any cargo loss or damage, or for delay in the delivery of
            a shipper’s freight, or for any actual or consequential damages
            resulting therefrom.
            <br />
            <br />
            CARRIER shall defend, indemnify and hold BROKER and its shipper
            customer harmless from any claims, actions or damages, arising out
            of its performance under this Agreement, including cargo loss and
            damage, theft, delay, damage to property, and personal injury or
            death, and BROKER shall defend, indemnify, and hold CARRIER harmless
            from any claims, actions, or damages, including cargo loss and
            damage, theft, delay, property damage, bodily injury or death,
            arising out of its performance hereunder. Neither Party shall be
            liable to the other for any claims, actions or damages due to the
            negligence, culpable conduct or intentional act of the other Party,
            or the shipper. The obligation to defend shall include all costs of
            defense as they accrue.
            <br />
            <br />
            Except for CARRIERS’S liability under Paragraph 10, unless otherwise
            agreed in writing, and regardless of whether the Parties’ insurance
            as referred to in this Agreement above is valid or provides
            coverage, the Parties’ indemnity obligations shall not exceed the
            monetary insurance limits referred to in the paragraph above.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">9.</p>
          <p className="sub-text">
            <b>No Broker Control-</b> The Parties agree that BROKER will not
            assert any control nor have any right to exercise control over a
            shipper’s freight, including, but not limited to, taking possession
            of a shipper’s freight, and BROKER shall not direct or control the
            routes taken by CARRIER in the transportation of a shipper’s
            freight.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">10.</p>
          <p className="sub-text">
            <b>Carrier Liability-</b> CARRIER hereby assumes the liability of a
            motor carrier as provided in §14706 of Title 49 of the United States
            Code (the Carmack Amendment), and all claims for loss, damage and/or
            salvage will be handled and processed in accordance with 49 C.F.R.
            Part 370.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">11.</p>
          <p className="sub-text">
            <b>Bills of Lading-</b> <br />
            <br />
            <div className="sub-heading-container">
              <p className="sub-heading">a.</p>
              <p className="sub-text">
                For each shipment tendered to CARRIER, CARRIER will provide to
                the shipper a standard bill of lading that is in accordance with
                49 C.F.R. §373, listing the consignor and consignee, the origins
                and destinations, the number of packages, the description of the
                freight, and the weight, volume or measurement of the freight.
                The Parties agree that BROKER will not be a party to the bill of
                lading.
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">b.</p>
              <p className="sub-text">
                CARRIER acknowledges that BROKER should not be listed on the
                bill of lading and that if BROKER is listed on the Bill of
                Lading as the carrier this will occur for the convenience of the
                shipper only and CARRIER at all times is the actual carrier of
                goods and BROKER’S role is limited to arranging for
                transportation. In the event BROKER’S name is listed on the bill
                of lading, shipping manifest or other similar document, as the
                carrier, CARRIER shall cross-out or otherwise remove BROKER’S
                name and enter CARRIER’S name as applicable.
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">c.</p>
              <p className="sub-text">
                CARRIER understands that re-brokering and double brokering may
                be prohibited by law and will not re-broker, assign or interline
                the shipments hereunder without the express written consent of
                BROKER prior to the shipment being tendered to any other
                CARRIER. If CARRIER breaches this provision, BROKER shall have
                the right of paying the monies it owes CARRIER directly to the
                delivering carrier, in lieu of payment to CARRIER, and BROKER
                shall thereby be released from any further obligation to pay
                CARRIER. Upon Broker’s payment to delivering carrier, CARRIER
                shall not be released from any liability to BROKER under this
                Agreement. IN ADDITION TO THE INDEMNITY OBLIGATION IN PARAGRAPH
                7, CARRIER WILL BE LIABLE FOR CONSEQUENTIAL DAMAGES FOR
                VIOLATION OF THIS PARAGRAPH.
                <br />
                <br />
                <div className="sub-heading-container">
                  <p className="sub-heading">i.</p>
                  <p className="sub-text">
                    The Parties agree that the shipment of freight will move
                    under the terms and conditions listed in the bill of lading,
                    except where inconsistent with the terms of this Agreement.
                  </p>
                </div>
                <div className="sub-heading-container">
                  <p className="sub-heading">ii.</p>
                  <p className="sub-text">
                    CARRIER agrees to list itself on the bill of lading as the
                    party in possession and control of the freight.
                  </p>
                </div>
                <div className="sub-heading-container">
                  <p className="sub-heading">iii.</p>
                  <p className="sub-text">
                    The terms and conditions of the bill of lading shall not
                    operate to alter or modify the terms of this Agreement
                    between CARRIER and BROKER.
                  </p>
                </div>
                <div className="sub-heading-container">
                  <p className="sub-heading">iv.</p>
                  <p className="sub-text">
                    CARRIER shall issue a bill of lading in compliance with 49
                    U.S.C. §80101 et seq., 49 C.F.R. §373.101 (and any
                    amendments thereto), for the property it receives for
                    transportation under this Agreement. Unless otherwise agreed
                    in writing, CARRIER shall become responsible/liable for the
                    freight when it takes/receives possession thereof, and the
                    trailer(s) is loaded, regardless of whether a bill of lading
                    has been issued, and/or delivered to CARRIER, and which
                    responsibility/liability shall continue until delivery of
                    the shipment to the consignee and the consignee signs the
                    bill of lading or delivery receipt. Any terms of the bill of
                    lading (including but not limited to payment terms, released
                    rates or released value) inconsistent with the terms of this
                    Agreement shall be ineffective. Failure to issue a bill of
                    lading or sign a bill of lading acknowledging receipt of the
                    cargo by CARRIER shall not affect the liability of CARRIER.
                    Said Bills of Lading are intended by the Parties to be Bills
                    of Lading, as that term is interpreted under the Carmack
                    Amendment and applicable law and not merely as “delivery
                    receipts”, “freight receipts” or any similar term.
                  </p>
                </div>
              </p>
            </div>
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">12.</p>
          <p className="sub-text">
            <b>Non-Solicitation of Shippers-</b> CARRIER agrees that it will not
            directly or indirectly conduct business with any shipper whose
            freight was transported pursuant to this Agreement for a period of
            two (2) years beginning with the last day such service was performed
            for that shipper. The Parties agree that a breach of this provision
            shall entitle BROKER, as reasonable liquidated damages and not as a
            penalty, to the full amount of commissions and/or compensation under
            the terms set forth in this Agreement that would have been due to
            BROKER had it arranged for the movement of said freight.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">13.</p>
          <p className="sub-text">
            <b>Assignment/Modifications of Agreement- </b> Neither CARRIER or
            BROKER may assign or transfer any rights under this Agreement, in
            whole or in part, without the prior written consent of the other
            party. Further, neither CARRIER nor BROKER may amend or modify the
            terms of this Agreement without the prior written consent of an
            expressly authorized official of the other party. For BROKER, only a
            company official with the title of Vice President or higher is
            authorized to agree to amendments to this Agreement. Any amendments
            or modifications to this Agreement not agreed to by both CARRIER and
            BROKER shall be null and void.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">14.</p>
          <p className="sub-text">
            <b>Insurance -</b> CARRIER shall furnish BROKER with Certificate(s)
            of Insurance; financial responsibility or insurance policies
            providing thirty (30) days advance written notice of cancellation or
            termination; and unless otherwise agreed, subject to the following
            minimum limits:
            <br />
            <br />
            <div className="sub-heading-container">
              <p className="sub-heading">A.</p>
              <p className="sub-text">general liability $1,000,000;</p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">B.</p>
              <p className="sub-text">
                commercial auto or commercial motor vehicle insurance
                $1,000,000, ($5,000,000 if transporting hazardous materials
                including environmental damages due to release or discharge of
                hazardous substances; hazmat carriers must have endorsement
                CA9948, sudden and accidental pollution coverage, and this
                endorsement must be shown on the Certificate of Insurance
                provided to BROKER);
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">C.</p>
              <p className="sub-text">
                cargo damage/loss, $100,000 This coverage must be All Risk Broad
                Form Motor Truck Cargo Legal Liability Coverage. The coverage
                provided under the policy shall have no exclusions or
                restrictions of any type that would foreseeably preclude
                coverage relating to cargo claims including, but not limited to,
                exclusions of unattended or unattached trailers, unattended or
                unlocked vehicles, theft, or for any commodities transported
                under this Agreement, refrigeration breakdown or lack of
                refrigerator fuel. Furthermore, if the commodity being hauled is
                refrigerated, refrigeration breakdown coverage will be provided
                and the CARRIER will honor and abide by the servicing
                requirements set forth in the insurance policy or endorsement.
                Furthermore, if the commodity being hauled is on a flatbed or
                similar open conveyance, that there be no exclusion for wetness,
                rust, corrosion or moisture.
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">D.</p>
              <p className="sub-text">
                workers’ compensation with limits required by law.
              </p>
            </div>
            <br />
            Except for the higher coverage limits which may be specified above,
            the insurance policies and financial responsibility shall comply
            with minimum requirements of the Federal Motor Carrier Safety
            Administration and any other applicable regulatory state agency.
            Nothing in this Agreement shall be construed to avoid CARRIER’S
            liability due to any exclusion or deductible of any insurance policy
            or to limit CARRIER’S liability for contribution and/or
            indemnification and defense of the BROKER.
            <br />
            <br />
            Coverage must be written with a CARRIER rated A- or better as rated
            by AM Best Company. When an intrastate policy is issued, BROKER must
            be named as an additional insured.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">15.</p>
          <p className="sub-text">
            <b>
              <u>Miscellaneous</u>{" "}
            </b>{" "}
            <br />
            <br />
            <div className="sub-heading-container">
              <p className="sub-heading">a.</p>
              <p className="sub-text">
                <b>Non-Exclusive Agreement:</b>CARRIER and BROKER acknowledge
                and agree that this contract does not bind the respective
                Parties to exclusive services to each other. Either party may
                enter into similar agreements with other carriers, brokers, or
                freight forwarders.
              </p>
            </div>
            <div className="sub-heading-container">
              <p className="sub-heading">b.</p>
              <p className="sub-text">
                <b>Waiver of Provisions:</b>
                <br />
                <br />
                <div className="sub-heading-container">
                  <p className="sub-heading">i.</p>
                  <p className="sub-text">
                    Failure of either Party to enforce a breach of waiver of any
                    provision or term of this Agreement shall not be deemed to
                    constitute a waiver of any subsequent failure or breach, and
                    shall not affect or limit the right of either Party to
                    thereafter enforce such a term or provision.
                  </p>
                </div>
                <div className="sub-heading-container">
                  <p className="sub-heading">ii.</p>
                  <p className="sub-text">
                    This Agreement is for specified services pursuant to 49
                    U.S.C.§14101(b). To the extent that terms and conditions
                    herein are inconsistent with Part (b), Subtitle IV, of Title
                    49 U.S.C. (ICC Termination Act of 1995), the Parties
                    expressly waive any or all rights and remedies they may have
                    under the Act.
                  </p>
                </div>
              </p>
            </div>
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">16.</p>
          <p className="sub-text">
            <b>Severability- </b> If any portion or provision of this Agreement
            is determined by a court of competent jurisdiction to be invalid or
            unenforceable, The Parties agree that said portion or provision of
            the Agreement shall be severable, and that the remaining provisions
            of the Agreement shall continue in full force and effect.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">17.</p>
          <p className="sub-text">
            <b> Notices- </b> Any and all written or electronic notices required
            or permitted to be given under this Agreement shall be addressed as
            follows:
            {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                (BROKER)
                <br />
                {realTenantDetails?.tenantName_chr}
                <br />
                <div class="horizontal-line"></div>
                <div class="horizontal-line"></div>
                <div class="horizontal-line"></div>
                <div class="horizontal-line"></div>
              </div>
              <div>
                (CARRIER)
                <br />
                {carrierData?.carrierName_chr}
                <br />
                <div class="horizontal-line">Attn:</div>
                <div class="horizontal-line"></div>
                <div class="horizontal-line"></div>
                <div class="horizontal-line"></div>
              </div>
            </div> */}
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">18.</p>
          <p className="sub-text">
            <b>Force Majeure- </b> In the event that fire, flood, other natural
            disaster, war, embargo, riot, or civil disobedience prevents the
            performance of either BROKER or CARRIER’S obligations under this
            agreement, that party shall not be liable to the other party for
            such failure to perform.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">19.</p>
          <p className="sub-text">
            <b>Choice of Law and Venue- </b> All issues concerning the
            construction, interpretation, validity, and enforceability of this
            Agreement, and any other dispute arising out of this Agreement,
            whether in a court of law or in alternative dispute resolution,
            shall be governed by and construed and enforced in accordance with
            the laws of the State of {realTenantDetails?.brokerBasedOn_chr}, including the applicable statutes
            of limitations under {realTenantDetails?.brokerBasedOn_chr} law, without giving effect to any choice
            of law provision applying the laws of another jurisdiction.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">20.</p>
          <p className="sub-text">
            <b>Indemnification- </b> CARRIER will indemnify and hold harmless
            BROKER, its employees, officers, directors, agents, principals and
            assigns from any liability, settlements, judgments, verdicts,
            attorney fees or expense or any nature whatsoever arising out of any
            claims, demands or suits against BROKER which in any way relate to a
            claim of BROKER’s liability or culpability for the actions of
            CARRIER, including negligent or improper hiring or retention of the
            CARRIER, its employees (statutory or otherwise) agents, principals,
            officers, directors, assigns or anyone acting by or for CARRIER, for
            any aspect of the transportation of freight, public liability,
            personal injury, bodily injury, emotional or mental distress,
            wrongful death, loss of consortium, cargo liability or any claim or
            cause of action recognized by any state, municipality, county or any
            jurisdiction, Administrative Agency, or the Government of the United
            States. CARRIER agrees to have insurance to cover its
            indemnification obligations under this section, but CARRIER’s
            indemnification obligations are not capped by the amount of any
            available insurance.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">21.</p>
          <p className="sub-text">
            <b>Entire Agreement- </b> This Agreement, including all appendices
            and addenda, constitutes the entire agreement intended by and
            between The Parties and supersedes all prior agreements,
            representations, warranties, and understandings, whether oral or in
            writing.
          </p>
        </div>
        <div className="sub-heading-container">
          <p className="sub-heading">22.</p>
          <p className="sub-text">
            <b>Modification of Agreement - </b> This Agreement and Exhibit A et
            seq. attached may not be amended, except by mutual written
            agreement, or the procedures set forth above.
          </p>
        </div>
        <br></br>
        <div className="sub-heading-container">
          <p class="text-justify">
            <b>IN WITNESS WHEREOF,</b> The Parties have caused this Agreement to
            be executed on the effective date listed above in their respective
            names by their fully authorized representatives below:
          </p>{" "}
        </div>
        <br></br>
        {/* <p class="text-justify">
          WHEREAS, “BROKER” is a person (or company) who arranges with an
          operator to carry the goods of another person (or company), for
          compensation and by commercial motor vehicle and may be duly
          registered where required.
        </p> */}
        <div class="card" style={{ marginTop: "40px" }}>
          <div class="card-body">
            <div class="container">
              <h5 class="card-title">Carrier Details</h5>
              <p>
                MC# / DOT -{" "}
                <b>
                  {carrierData?.mC_FF_MXNumber_chr
                    ? carrierData?.mC_FF_MXNumber_chr
                    : carrierData?.usdotNumber_chr}
                </b>
              </p>
              <p>
                EIN -<b>{carrierData?.einNumber_chr}</b>
              </p>

              <p>
                Company Name / DBA - <b>{carrierData?.carrierName_chr}</b>
              </p>
              <p>
                First Name -{" "}
                <b>{carrierData?.primaryContactName_chr?.split(" ")[0]}</b>
              </p>
              <p>
                Last Name -{" "}
                <b>{carrierData?.primaryContactName_chr?.split(" ")[1]}</b>
              </p>
              <p>
                Phone Number- <b>{carrierData?.phoneNumber_chr}</b>
              </p>
              <p>
                Address -
                <b>
                  {carrierData?.addressLine1_chr +
                    ", " +
                    carrierData?.addressLine2_chr}
                </b>
              </p>
              <p>
                City- <b>{carrierData?.city_chr}</b>
              </p>
              <p>
                State-<b>{carrierData?.stateName_chr}</b>
              </p>
              <p>
                Zip Code-<b>{carrierData?.zipPostalCode_chr}</b>
              </p>
              <p>
                Email Address - <b>{carrierData?.primaryContactEmail_chr}</b>
              </p>
              <br />

              <h6>INSURANCE COMPANY DETAILS </h6>

              <p>
                Insurance Company -<b>{carrierData?.cargoInsurance_chr}</b>
              </p>
              <p>
                Contact Name - <b>{carrierData?.insuranceContactName_chr}</b>
              </p>
              <p>
                Phone Number – <b>{carrierData?.insurancePhoneNumber_chr}</b>
              </p>
              <p>
                Insurance Email – <b>{carrierData?.insuranceEmail_chr}</b>
              </p>

              {carrierData.factoringCoName_chr !== null && (
                <>
                  <br />
                  <h6>FACTORING DETAILS</h6>

                  <p>
                    Name- <b>{carrierData?.factoringCoName_chr}</b>
                  </p>
                  <p>
                    Factoring Contact Name -{" "}
                    <b>{carrierData?.factoringContactName_chr}</b>
                  </p>
                  <p>
                    Factoring Address -{" "}
                    <b>{carrierData?.factoringCoAddressLine1_chr}</b>
                  </p>
                  <p>
                    Factoring Phone Number -{" "}
                    <b>{carrierData?.factoringCoPhoneNumber_chr}</b>
                  </p>
                </>
              )}

              {carrierData?.financialInstitutionName_chr !== null && (
                <>
                  <br />
                  <h6>REMITTANCE DETAILS</h6>

                  <p>
                    Financial Institution Name-{" "}
                    <b>{carrierData?.financialInstitutionName_chr}</b>
                  </p>
                  <p>
                    Account Number - <b>{carrierData?.accountNumber_chr}</b>
                  </p>
                  <p>
                    Routing number - <b>{carrierData?.routingNumber_chr}</b>
                  </p>
                </>
              )}

              {carrierData?.checksPayableTo_chr !== null && (
                <>
                  <br />
                  <h6>REMITTANCE DETAILS</h6>

                  <p>
                    Check Payable to- <b>{carrierData?.checksPayableTo_chr}</b>
                  </p>
                  <p>
                    Mailing address - <b>{carrierData?.mailingAddress_chr}</b>
                  </p>
                </>
              )}
            </div>
          </div>

          <h5 className="drawText">Please Draw your Signature here :</h5>
          <SignatureCanvas
            ref={signPadRef}
            penColor={penColor}
            canvasProps={{ className: "signCanvas" }}
            velocityFilterWeight={0.5}
            onBegin={signingStart}
            onEnd={saveSign}
          ></SignatureCanvas>
          <div className="d-flex flex-row justify-content-start btnwrapper">
            <button className="btnStyle" onClick={clearSign}>
              Clear
            </button>
          </div>
        </div>
        <div class="input-group mt-3 mb-2">
          <input
            onChange={handleOnChange}
            class="form-check-input"
            type="checkbox"
            name="termscheckbox"
            value="isTermsAccepted"
            id="termscheckbox"
          />{" "}
          <label
            class="form-check-label"
            style={{ marginLeft: 10 }}
            for="termscheckbox"
          >
            I Accept Terms and Conditions *
          </label>
        </div>
        <div style={{ marginBottom: "30px" }}>
          <button
            disabled={!isTermsAccepted}
            style={{ paddingLeft: 15, paddingRight: 15 }}
            className="getquotebtn"
            name="Submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default CarrierAgreement;
