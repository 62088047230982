

const initialState = {
    w9Form:null,
    insuranceForm:null,
    carrierAuthForm:null,
    NOA:null,
    voidCheck:null
  };
  
  const carrierDocsReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    if(type === "SET_CARREIRDOCS"){       
      return { ...state, w9Form: payload.w9, insuranceForm:payload.insur, carrierAuthForm:payload.carrierAuth, NOA:payload?.NOA, voidCheck:payload?.voidCheck };
    }else{    
      return state;
    } 
  };
  
  export default carrierDocsReducer;
  