import axios from "axios";
import swal from "sweetalert";
import { token } from "./token";
import { SAFER_WATCH } from "../redux/types/endPoints";
import { domainName } from "../redux/types/endPoints";

const getSaferWatchDetails = (params,authDetails, callback) => async (dispatch) => {
  dispatch({ type: "SET_SPINNER", payload: true });
  await axios
    .get(SAFER_WATCH, {
      params,
      headers: {
        Authorization:"",
        "X_domain_name": authDetails?.tenantName,
      },
    })
    .then((response) => {
      callback &&
        callback(
          response?.data?.["CarrierService32.CarrierLookup"]?.CarrierDetails
        );
      dispatch({ type: "SET_SPINNER", payload: false });
      // console.log('in try block', response?.data?.['CarrierService32.CarrierLookup']?.CarrierDetails?.FMCSAInsurance?.PolicyList?.PolicyItem,)
    })
    .catch((error) => {
      console.log("in catch block - safer watch", error);
      dispatch({ type: "SET_SPINNER", payload: false });
    });
};

export default getSaferWatchDetails;
