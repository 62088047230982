import React from 'react'
import InsuranceDetails from "./InsuranceDetails"

export default function InsurancePage() {
    return (
        <div>
            <InsuranceDetails/>
        </div>
    )
}





