import { SEND_EMAIL } from "../types/emailServices";
const initialState = {
  emailStatus: null,
};

const emailServicesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEND_EMAIL:
      return { ...state, emailStatus: payload };

    default:
      return state;
  }
};

export default emailServicesReducer;
