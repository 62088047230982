import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icons: <AiIcons.AiOutlineHome style={{ color: "#b00000", fontSize: "1.5rem", marginRight: "5px" }} />,
  },
  {
    title: "Shipper",
    path: "/shipper",
    icons: <FaIcons.FaShippingFast style={{ color: "#b00000", fontSize: "1.5rem", marginRight: "5px" }} />,
  },

  {
    title: "Carrier",
    path: "/carriers",
    icons: <FaIcons.FaSwatchbook style={{ color: "#b00000", fontSize: "1.5rem", marginRight: "5px" }} />,
  },

  {
    title: "About Us",
    path: "/about",
    icons: <BiIcons.BiBuildings style={{ color: "#b00000", fontSize: "1.5rem", marginRight: "5px" }} />,
  },
  {
    title: "Contact",
    path: "/contact",
    icons: <MdIcons.MdContactPhone style={{ color: "#b00000", fontSize: "1.5rem", marginRight: "5px" }} />,
  },
];
