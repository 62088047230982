import React from 'react'
// import { Link } from 'react-router-dom'

function ThankyouPage(){
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">THANK YOU!</h1>
                <p className="fs-3"> <span className="text-danger">Onboarded</span> Successfully!</p>
                <p className="lead">
                    Our agent will approach you soon.
                  </p>
                {/* <Link to="/" className="btn btn-danger">Go Home</Link> */}
            </div>
        </div>
  )
}

export default ThankyouPage