
const initialState = {
    spinnerState:false,
  };
  
  const spinnerReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    if(type === "SET_SPINNER"){ 
    //   console.log('yes-1')    
      return { ...state, spinnerState: payload };
    }else{
    //   console.log('yes-2')   
      return state;
    } 
  };
  
  export default spinnerReducer;
  