// import { TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
// import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import SuggestionListComponent from "../suggetionList/component";
// import { getLocationData } from "../../services/api/location/index";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { getGooglePlaces } from "../../redux/actions/googleServices";
import debounce from "lodash/debounce";
// import { TextField } from "@mui/material";



function PlacesAutocomplete({
 
  onClickSuggestion,
  onChange,
  handleOnBlur,
  ...inputFieldProps
}) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);



  

  const dispatch = useDispatch();
  
  const [placePredictions, setPlacePredictions] = useState([]);


  useEffect(() => {
   
    if (placePredictions.length) {
      setSuggestions(placePredictions);
    }
  }, [placePredictions]);

   const newOnChangeForThisComponent = (e) => {
 

      // getPlacePredictions({ input: e.target.value });
      if (e.target.value.length > 1) {
        dispatch(
          getGooglePlaces(
            { input: e.target.value },
            (res) => {
             
              setPlacePredictions(res.predictions);
            },
            (err) => console.log("error from getGooglePlacesApi", err)
          )
        );
      }

   }

  const debouncedSearch = useCallback(
    debounce((q) => newOnChangeForThisComponent(q), 500),
    []
  );

  return (
    <>
      <input
        onBlur={(e) => {
          setShowSuggestions(false);
          handleOnBlur(e);
        }}
        onFocus={() => {
          setShowSuggestions(true);
        }}
        onChange={(e) => {
        //   e;
          onChange(e);
          debouncedSearch(e);
        }}  
        {...inputFieldProps}
      />
      {showSuggestions && (
        <div style={{width:'-webkit-fill-available'}} className="position-absolute">
          <div
            className={
                inputFieldProps?.loadsInput === true
                  ? "position-relative top-1 bg-white"
                  : "position-relative top-8 bg-white"
              }
          >
            <SuggestionListComponent
              data={suggestions}
              onClose={() => setShowSuggestions(true)}
              onClick={onClickSuggestion}
              renderData={(item) => (
                <span className="text-start">
                  {" "}
                  {item?.description}
                    
                </span>
              )}
            />
          </div>
        </div>
      )}{" "}
    </>
  );
}

export default PlacesAutocomplete;
