import "./App.css";
// import { BrowserRouter } from "react-router-dom";
import Sidebar from "../src/Components/navbar/Sidebar";
import Footer from "../src/Components/Footer/Footer";
// import componentRoutes from "./routes/index";
import { Provider } from "react-redux";
import store from "./redux/store";

import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./Pages/Homepage/Homepage";
import Carriers from "./Pages/Carriers";
import CarriersMap from "./Pages/CarriersMap/CarriersMap";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import InsurancePage from "./Pages/InsuranceDetails";
import Shipper from "./Pages/Shipper";
import Getquote from "./Pages/Getquote";
// import ScrollToTop from './Components/ScrollToTop'
import PageNotFound from "./Pages/errorPage/PageNotFound";
import TermsAndConditions from "./Pages/TermsCondition/index";
import PrivacyPolicy from "./Pages/PrivacyPolicy/privacyPolicy";
import CarrierAgreement from "./Pages/CarrierAgreement/carrierAgreement";
import ThankyouPage from "./Pages/ThankYou/thankyou";
import { useParams } from "react-router-dom";

function App() {
  let { tenantId } = useParams();
  tenantId = tenantId || localStorage.getItem("tenantName");
  console.log(useParams(),"useParamValueInApp.js")
  // sessionStorage.setItem('hasReloaded', 'true');
 

  return (
    <Provider store={store}>
      <div className="App">
        {/* <Sidebar /> */}

        <div className="routes">
          <componentRoutes />
          {/* <ScrollToTop> */}
          <Routes>
            {/* <Route path="/" element={<Homepage/>} />
                  <Route path="/getquote" element={<Getquote/>} />
                  <Route path="/about" element={<About/>} />
                  <Route path="/contact" element={<Contact/>} /> */}
            <Route
              path="/:tenantId/onboarding/:carrierId/:userId/"
              element={<InsurancePage />}
            />
            {/* <Route path="/shipper" element={<Shipper/>} />
                  <Route path="/carriers" element={<Carriers/>} /> */}
            <Route
              path="/:tenantId/carrier-agreement/:carrierId/:userId"
              element={<CarrierAgreement />}
            />
            {/* <Route path="/carriersmap" element={<CarriersMap/>} />        
                  <Route path="/terms" element={<TermsAndConditions/>} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy/>} />              */}
            <Route path="/not-found" element={<PageNotFound />} />
            <Route path="/:tenantId/thankyou" element={<ThankyouPage />} />
            <Route
              path="*"
              element={<Navigate replace to={`/not-found`} />}
            />
          </Routes>
          {/* </ScrollToTop> */}
        </div>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
