// import swal from "sweetalert";
import {GET_ALL_COUNTRY} from "../redux/types/endPoints"
import {token} from './token';
import axios from "axios";
import { domainName } from '../redux/types/endPoints';


  export const getCountryList = (params,authDetails,callback, errorCallBack) => { 
    return async (dispatch) => {  
    await axios.get(GET_ALL_COUNTRY, { params, headers:{
        'Authorization':"",
        'X_domain_name':authDetails?.tenantName
    }}).then((response) => {         
        callback && callback(response?.data?.refCountry); 
        })
        .catch((error) => {
            errorCallBack && errorCallBack(error?.response?.data);       
        });
    };
  };