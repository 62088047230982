
const initialState = {
  carriersAllLocations: [], 
};

const carriersLocationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if(type === "GET_ALL_CARRIERS"){
    console.log('yes-1')
    console.log( { ...state, carriersAllLocations: payload },'220');
    return { ...state, carriersAllLocations: payload };
  }else{
    console.log('yes-4')
    // console.log( state,'230')
    return state;
  }
};

export default carriersLocationReducer;
