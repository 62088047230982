import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Getquoteinputs from "../../Components/getquoteInputs";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { getCarrierDetails } from "../../api/carrier";
import { addEditCarrier } from "../../api/carrier";
import { token } from "../../api/token";
import axios from "axios";
import { MdExpandMore } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import swal from "sweetalert";
import InputMask from "react-input-mask";
import { getStateByCountry } from "../../api/stateByCountry";
import getInsuranceDetails from "../../api/saferWatch";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { postCarrierDocuments } from "../../api/carrier";
// import { POST_CARRIER_DOCS } from "../../redux/types/endPoints";
// import { domainName } from "../../redux/types/endPoints";
import { userId } from "../../redux/types/endPoints";
import PlacesAutocomplete from "../../Components/placesAutocomplete/component";
import { FaCheck, FaHandPointRight } from "react-icons/fa";
import { GetAddressByPlaceId } from "../../redux/actions/googleServices";
import {
  getFormattedAddressFromPlaces,
  getStateIdFromStateName,
} from "../../utils/utility";
import { getCountryList } from "../../api/getAllCountries";

const InsuranceDetails = () => {
  const { carrierId, tenantId: tenantName, userId, token } = useParams();
  const authDetails = { carrierId, tenantName, userId };
  console.log(authDetails, "useParamValueInInsaurance.js");
  const currentUrl = window.location.pathname;
  const parts = currentUrl.split('/');
  const encodedCarrierID = parts[3]
  console.log(encodedCarrierID, "decodedCarrierId");
  // localStorage.setItem("tenantName", tenantName);
  const [NOA, setNOA] = React.useState(null);
  const [voidCheckFile, setVoidCheckFile] = useState(null);
  const [w9File, setW9File] = React.useState(null);
  const [insuranceForm, setinsuranceForm] = React.useState(null);
  const [carrierAuthorityForm, setCarrierAuthorityForm] = React.useState(null);
  const [linkExpired, setLinkExpired] = React.useState(false);
  const [showDetailsOfRemittance, setShowDetailsOfRemittance] =
    React.useState(null);
  const [countryList, setCountryList] = React.useState([]);
  const [achOrCheck, setAchOrCheck] = React.useState(null);

  const [uploaded, setUploaded] = useState({
    file: false,
    insuranceForm: false,
    carrierAuthorityForm: false,
    carrierNoaFile: false,
    voidCheck: false,
  });

  const [disabledInputs, setDisabledInputs] = useState({
    file: false,
    insuranceForm: false,
    carrierAuthorityForm: false,
    carrierNoaFile: false,
    voidCheck: false,
  });

  const spinner = useSelector((state) => state?.spinner?.spinnerState);

  const [addEditCarrierResponseStatus, setAddEditCarrierResponseStatus] =
    React.useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCFiwWY4jVZtAoFcW1h3NUwVaL2BIBISbs",
    libraries: window.config.libraries,
  });

  var initialRequiredRedColor = {
    einNumber_chr: false,
    cargoInsurance_chr: false,
    insuranceContactName_chr: false,
    insurancePhoneNumber_chr: false,
    ownFactoring_chr: false,
    achOrCheque_chr: false,
    factoringCoName_chr: false,
    factoringContactName_chr: false,
    factoringCoPhoneNumber_chr: false,
    factoringEmail_chr: false,
    factoringCoAddressLine1_chr: false,
    paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr: false,
    financialInstitutionName_chr: false,
    accountNumber_chr: false,
    mailingAddress_chr: false,
    remitToCountry_lng: false,
    insuranceEmail_chr: false,
    routingNumber_chr: false,
    checksPayableTo_chr: false,
    file: false,
    voidCheck: false,
    carrierNoaFile: false,
    insuranceForm: false,
    carrierAuthorityForm: false,
  };
  const [requiredRedColor, setRequiredRedColor] = useState(
    initialRequiredRedColor
  );

  const initialFormData = {
    addressLine1_chr: null,
    addressLine2_chr: null,
    accountNumber_chr: null,
    achOrCheque_chr: null,
    cargoInsuranceExpirationDate_dtm: null,
    cargoInsurance_chr: null,
    carrierID_ids: null,
    carrierName_chr: null,
    carrier_Count_Excluding_Criteria_int: null,
    city_chr: null,
    client_Count_Excluding_Criteria_int: null,
    countryId_lng: null,
    countryName_chr: null,
    einNumber_chr: null,
    factoringCoAddressLine1_chr: null,
    factoringCoAddressLine2_chr: null,
    factoringCoCity_chr: null,
    factoringCoName_chr: null,
    factoringCoPhoneExt_chr: null,
    factoringCoPhoneNumber_chr: null,
    factoringCoStateCode_chr: null,
    factoringCoStateId_lng: null,
    factoringCoStateName_chr: null,
    factoringCoZipPostalCode_chr: null,
    factoringContactName_chr: null,
    factoringEmail_chr: null,
    insuranceContactName_chr: null,
    insurancePhoneNumber_chr: null,
    is1099Vendor_ysn: null,
    isDeleted_ysn: null,
    ownFactoring_chr: null,
    lastUpdatedByUserID_chr: null,
    lastUpdatedDateTime_dtm: null,
    mC_FF_MXNumber_chr: null,
    ownFactoring_chr: null,
    paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr: null,
    paymentTerms_chr: null,
    phoneExt_chr: null,
    phoneNumber_chr: null,
    primaryContactEXT_chr: null,
    primaryContactEmail_chr: null,
    primaryContactFax_chr: null,
    primaryContactName_chr: null,
    primaryContactTelephone_chr: null,
    insuranceEmail_chr: null,
    primaryInsuranceExpirationDate_dtm: null,
    privateCarrierNotes_chr: null,
    financialInstitutionName_chr: null,
    mailingAddress_chr: null,
    routingNumber_chr: null,
    checksPayableTo_chr: null,
    row_Version_Binary_bin: null,
    stateCode_chr: null,
    stateId_lng: null,
    stateName_chr: null,
    taxID_chr: null,
    usdotNumber_chr: null,
    zipPostalCode_chr: null,
    isOnboarded_ysn: null,
    remitToCountry_lng: 1,
  };

  const [formData, setFormData] = useState(initialFormData);
  // console.log(formData, '153')
  const [disableFactoryOptions, setDisableFactoryOptions] = useState(null);
  const [hideLable, setHideLable] = useState(true);
  const [stateListData, setStateListData] = useState({});

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getStateByCountry(1, authDetails, (data) =>
        setStateListData((state) => ({ 1: data }))
      )
    );
    if (carrierId) {
      dispatch(
        getCarrierDetails(
          {
            Calling_UserID_chr: userId,
            CarrierID_lng: carrierId,
          },
          authDetails,
          (data) => {
            console.log(data?.tblCarrier, "console carrier tbl");
            if (data?.tblCarrier?.length === 0) {
              returnTo404Errorpage();
            } else if (data?.tblCarrier[0].isOnboarded_ysn === 1) {
              swal({
                title: "Link Expired",
                text: "You have already registered as carrier with us",
                icon: "error",
                dangerMode: true,
              }).then(function () {
                window.location = `${window.location.origin}/`;
              });
              setLinkExpired(true);
            } else {
              // setFormData(data?.tblCarrier[0]);
              dispatch(
                getInsuranceDetails(
                  { dotNumber: data?.tblCarrier[0]?.usdotNumber_chr },
                  authDetails,
                  (data2) => {
                    setFormData((state) => ({
                      ...state,
                      carrierID_ids: data?.tblCarrier[0]?.carrierID_ids,
                      carrierName_chr: data?.tblCarrier[0]?.carrierName_chr,
                      addressLine1_chr: data?.tblCarrier[0]?.addressLine1_chr,
                      addressLine2_chr: data?.tblCarrier[0]?.addressLine2_chr,
                      countryName_chr: data?.tblCarrier[0]?.countryName_chr,
                      stateName_chr: data?.tblCarrier[0]?.stateName_chr,
                      city_chr: data?.tblCarrier[0]?.city_chr,
                      zipPostalCode_chr: data?.tblCarrier[0]?.zipPostalCode_chr,

                      primaryContactName_chr:
                        data?.tblCarrier[0]?.primaryContactName_chr,
                      primaryContactEmail_chr:
                        data?.tblCarrier[0]?.primaryContactEmail_chr,
                      phoneNumber_chr: data?.tblCarrier[0]?.phoneNumber_chr,

                      einNumber_chr: data?.tblCarrier[0]?.einNumber_chr,

                      cargoInsurance_chr:
                        data2?.FMCSAInsurance?.PolicyList?.PolicyItem?.[0]
                          ?.companyName ||
                        data?.tblCarrier[0]?.cargoInsurance_chr,
                      insuranceContactName_chr:
                        data2?.CertData?.Certificate?.[0]?.Coverage?.[0]
                          ?.insurerName ||
                        data?.tblCarrier[0]?.insuranceContactName_chr,
                      insuranceEmail_chr:
                        data?.tblCarrier[0]?.insuranceEmail_chr,
                      insurancePhoneNumber_chr:
                        data2?.FMCSAInsurance?.PolicyList?.PolicyItem?.[0]
                          ?.phone ||
                        data?.tblCarrier[0]?.insurancePhoneNumber_chr,
                    }));
                  }
                )
              );
            }
          }
        )
      );
    } else {
      returnTo404Errorpage();
    }
  }, []);

  var isSatisfied = "";

  var requiredObject = {
    einNumber_chr: false,
    cargoInsurance_chr: false,
    insuranceContactName_chr: false,
    insurancePhoneNumber_chr: false,
    ownFactoring_chr: false,
    achOrCheque_chr: false,
    factoringCoName_chr: false,
    factoringContactName_chr: false,
    factoringCoPhoneNumber_chr: false,
    factoringEmail_chr: false,
    factoringCoAddressLine1_chr: false,
    file: false,
    voidCheck: false,
    paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr: false,
    carrierNoaFile: false,
    insuranceForm: false,
    carrierAuthorityForm: false,
    accountNumber_chr: false,
    financialInstitutionName_chr: false,
    mailingAddress_chr: false,
    insuranceEmail_chr: false,
    routingNumber_chr: false,
    checksPayableTo_chr: false,
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    if (!formData[name]) {
      setRequiredRedColor((state) => ({
        ...state,
        [name]: true,
      }));
      requiredObject = {
        ...requiredObject,
        [name]: true,
      };
    } else {
      setRequiredRedColor((state) => ({
        ...state,
        [name]: false,
      }));
      requiredObject = {
        ...requiredObject,
        [name]: false,
      };
    }
  };

  const handleform = (e, child) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(value, "on change values");

    if (name === "paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr") {
      if (value === "standardPay") {
        formData["paymentTerms_chr"] = '30';
      } else if (value === "quickPay") {
        formData["paymentTerms_chr"] = '7';
      }
    }

    if (name === "preferState_lng") {
      formData["preferState_lng"] = value;
    }

    if (name === "ownFactoring_chr") {
      setHideLable(false);
      if (value === "yes") {
        setFormData({
          ...formData,
          accountNumber_chr: null,
          financialInstitutionName_chr: null,
          mailingAddress_chr: null,

          remitToAddressLine1_chr: null,
          remitToCity_chr: null,
          remitToStateId_lng: 0,
          remitToCountry_lng: 0,
          remitToZipPostalCode_chr: null,
          checksPayableTo_chr: null,
          routingNumber_chr: null,
          ownFactoring_chr: "yes",
        });
        setDisableFactoryOptions("factory");
      } else {
        // setHideLable(false)
        setFormData({
          ...formData,
          factoringCoName_chr: null,
          factoringContactName_chr: null,
          factoringCoPhoneNumber_chr: null,
          factoringEmail_chr: null,
          ownFactoring_chr: "no",
          factoringCoAddressLine1_chr: null,
          factoringCoCity_chr: null,
          factoringCoStateId_lng: 0,
          factoringCoZipPostalCode_chr: null,
        });
        setNOA(null);
        setDisableFactoryOptions("remitt");
      }
    } else if (name === "achOrCheque_chr") {
      if (value === "ach") {
        setAchOrCheck("ach");
        setFormData({
          ...formData,
          mailingAddress_chr: null,

          remitToAddressLine1_chr: null,
          remitToCity_chr: null,
          remitToStateId_lng: 0,
          remitToCountry_lng: 0,
          remitToZipPostalCode_chr: null,
          checksPayableTo_chr: null,
          achOrCheque_chr: "ACH",
        });
        setRequiredRedColor((state) => ({
          ...state,
          mailingAddress_chr: false,
          remitToCountry_lng: false,
          checksPayableTo_chr: false,
          achOrCheque_chr: false,
        }));
        requiredObject = {
          ...requiredObject,
          mailingAddress_chr: false,
          remitToCountry_lng: false,
          checksPayableTo_chr: false,
          achOrCheque_chr: false,
        };
        setShowDetailsOfRemittance("ach");
      } else {
        setAchOrCheck("Check");
        setFormData({
          ...formData,
          financialInstitutionName_chr: null,
          accountNumber_chr: null,
          routingNumber_chr: null,
          achOrCheque_chr: "Check",
        });
        setRequiredRedColor((state) => ({
          ...state,
          financialInstitutionName_chr: false,
          accountNumber_chr: false,
          routingNumber_chr: false,
          achOrCheque_chr: false,
        }));
        requiredObject = {
          ...requiredObject,
          financialInstitutionName_chr: false,
          accountNumber_chr: false,
          routingNumber_chr: false,
          achOrCheque_chr: false,
        };
        setShowDetailsOfRemittance("cheque");
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setRequiredRedColor((state) => ({
        ...state,
        [name]: false,
      }));
      requiredObject = {
        ...requiredObject,
        [name]: false,
      };
    }
  };

  const returnTo404Errorpage = () => {
    navigate(`/not-found`);
  };

  const checkRequiredFields = () => {
    // Utility function to update the required state
    const updateRequiredState = (field, value) => {
      setRequiredRedColor((state) => ({
        ...state,
        [field]: value,
      }));
      requiredObject = {
        ...requiredObject,
        [field]: value,
      };
    };

    const fieldsToCheck = [
      'einNumber_chr',
      'cargoInsurance_chr',
      'insuranceContactName_chr',
      'insurancePhoneNumber_chr',
      'insuranceEmail_chr',
      'ownFactoring_chr',
      'factoringCoName_chr',
      'factoringContactName_chr',
      'factoringCoPhoneNumber_chr',
      'factoringEmail_chr',
      'factoringCoAddressLine1_chr',
      'achOrCheque_chr',
      'financialInstitutionName_chr',
      'mailingAddress_chr',
      'remitToCountry_lng',
      'checksPayableTo_chr',
      'routingNumber_chr',
      'accountNumber_chr',
    ];

    // Check all the fields
    fieldsToCheck.forEach((field) => {
      if (formData[field] === null || formData[field] === "" || formData[field] === undefined) {
        updateRequiredState(field, true);
      } else {
        updateRequiredState(field, false);
      }
    });

    if (showDetailsOfRemittance === "ach") {
      if (voidCheckFile === null || voidCheckFile === "" || voidCheckFile === undefined) {
        updateRequiredState('voidCheck', true);
      } else {
        updateRequiredState('voidCheck', false);
      }
    }

    if (formData.ownFactoring_chr === "no") {
      if (
        formData.paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr === null ||
        formData.paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr === ""
      ) {
        updateRequiredState('paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr', true);
      } else {
        updateRequiredState('paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr', false);
      }
    }

    // Check file uploads
    const filesToCheck = {
      file: w9File,
      insuranceForm: insuranceForm,
      carrierAuthorityForm: carrierAuthorityForm,
    };

    Object.keys(filesToCheck).forEach((fileKey) => {
      if (filesToCheck[fileKey] === null || filesToCheck[fileKey] === "" || filesToCheck[fileKey] === undefined) {
        updateRequiredState(fileKey, true);
      } else {
        updateRequiredState(fileKey, false);
      }
    });

    // Check NOA file only if factoring is not 'no'
    if (formData.ownFactoring_chr !== "no") {
      if (NOA === null || NOA === "" || NOA === undefined) {
        updateRequiredState('carrierNoaFile', true);
      } else {
        updateRequiredState('carrierNoaFile', false);
      }
    }

    if (disableFactoryOptions === "remitt") {
      [
        'factoringCoName_chr',
        'factoringContactName_chr',
        'factoringCoPhoneNumber_chr',
        'factoringEmail_chr',
        'factoringCoAddressLine1_chr',
        'ownFactoring_chr',
        'carrierNoaFile',
      ].forEach((field) => updateRequiredState(field, false));
      setNOA(null);
    } else {
      [
        'mailingAddress_chr',
        'remitToCountry_lng',
        'checksPayableTo_chr',
        'accountNumber_chr',
        'financialInstitutionName_chr',
        'routingNumber_chr',
        'ownFactoring_chr',
        'achOrCheque_chr',
      ].forEach((field) => updateRequiredState(field, false));
    }

    if (achOrCheck === "ach") {
      [
        'mailingAddress_chr',
        'remitToCountry_lng',
        'checksPayableTo_chr',
        'achOrCheque_chr',
      ].forEach((field) => updateRequiredState(field, false));
    } else {
      [
        'accountNumber_chr',
        'financialInstitutionName_chr',
        'routingNumber_chr',
        'achOrCheque_chr',
      ].forEach((field) => updateRequiredState(field, false));
    }

    const haveSameData = (obj1, obj2) => {
      const obj1Length = Object.keys(obj1).length;
      const obj2Length = Object.keys(obj2).length;

      if (obj1Length === obj2Length) {
        return Object.keys(obj1).every(
          (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
        );
      }
      return false;
    };

    const checkObjects = haveSameData(requiredObject, initialRequiredRedColor);

    if (checkObjects) {
      return (isSatisfied = "yes");
    } else {
      const errorFields = Object.fromEntries(
        Object.entries(requiredObject).filter(([key, value]) => value === true)
      );
      const finalErrorFields = Object.keys(errorFields).map((res) => {
        if (res.includes("_chr")) {
          return res.replace("_chr", "");
        } else {
          return res;
        }
      });

      const resultArray = finalErrorFields.map((str) => {
        const words = str.split(/(?=[A-Z])/);
        const capitalizedWords = words.map((word) => {
          if (word === "ein") {
            return word.toUpperCase();
          } else if (word === "Co") {
            return word.charAt(0).toUpperCase() + word.slice(1) + ".";
          } else {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }
        });
        return capitalizedWords.join(" ");
      });

      swal({
        title: "Please fill following field(s) first",
        text: `${resultArray.length > 1 ? resultArray.join(", ") : resultArray}`,
        icon: "error",
        dangerMode: true,
      });
      return (isSatisfied = "no");
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    checkRequiredFields();

    if (isSatisfied === "no") {
      return;
    } else {
      // Check if all required files are uploaded
      const requiredFiles = ['file', 'insuranceForm', 'carrierAuthorityForm'];
      if (formData.ownFactoring_chr === "no" && achOrCheck === "ach") {
        requiredFiles.push('voidCheck');
      } else if (formData.ownFactoring_chr === "yes") {
        requiredFiles.push('carrierNoaFile');
      }

      const missingFiles = requiredFiles.filter(fileKey => !uploaded[fileKey]);

      if (missingFiles.length > 0) {
        const formattedFiles = missingFiles.map(file => {
          const words = file.split(/(?=[A-Z])/);
          return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
        });

        swal({
          title: "Please upload the following document(s) first",
          text: formattedFiles.join(", "),
          icon: "error",
          dangerMode: true,
        });
        return;
      }

      const documentData = new FormData();
      dispatch(
        addEditCarrier(
          {
            ...formData,
            calling_UserID_chr: userId,
            cargoInsurance_chr: formData?.cargoInsurance_chr,
            insuranceContactName_chr: formData?.insuranceContactName_chr,
            insurancePhoneNumber_chr: formData?.insurancePhoneNumber_chr,
            insuranceEmail_chr: formData?.insuranceEmail_chr,
            tenantName: tenantName,
          },
          (data) => {
            setAddEditCarrierResponseStatus(data.status)
            navigate(`/${tenantName}/carrier-agreement/${encodedCarrierID}/${userId}`)
          }
        )
      );

    }
  };

  const handleFileSelect = (e) => {
    const name = e.target.name;
    const imageFile = e.target.files[0];

    if (!imageFile) {
      return;
    }

    if (!imageFile.name.match(/\.(pdf)$/)) {
      alert("Please Upload Pdf Only");
      e.target.value = null;
      return false;
    } else {
      if (name === "file") {
        setW9File(e.target.files[0]);
      } else if (name === "insuranceForm") {
        setinsuranceForm(e.target.files[0]);
      } else if (name === "carrierAuthorityForm") {
        setCarrierAuthorityForm(e.target.files[0]);
      } else if (name === "carrierNoaFile") {
        setNOA(e.target.files[0]);
      } else if (name === "voidCheck") {
        setVoidCheckFile(e.target.files[0]);
      }
    }
  };

  const handleVoidCheckFileSelect = (e) => {
    setVoidCheckFile(e.target.files[0]);
  };


  const handleUploadClick = (name) => {
    let fileToUpload = null;

    if (name === "file") {
      fileToUpload = w9File;
    } else if (name === "insuranceForm") {
      fileToUpload = insuranceForm;
    } else if (name === "carrierAuthorityForm") {
      fileToUpload = carrierAuthorityForm;
    } else if (name === "carrierNoaFile") {
      fileToUpload = NOA;
    } else if (name === "voidCheck") {
      fileToUpload = voidCheckFile;
    }

    if (fileToUpload) {
      const documentData = new FormData();
      documentData.append(name, fileToUpload);
      documentData.append("CarrierID_lng", formData?.carrierID_ids);
      documentData.append("Calling_UserID_chr", userId);

      // Check additional conditions
      // if (disableFactoryOptions === "factory" && name === "carrierNoaFile" && NOA !== null) {
      //   documentData.append("CarrierNoaFile", NOA);
      // } else if (showDetailsOfRemittance === "ach" && name === "voidCheck" && voidCheckFile !== null) {
      //   documentData.append("VoidCheck", voidCheckFile);
      // }

      dispatch(
        postCarrierDocuments(documentData, authDetails, (data) => {
          console.log('Upload successful:', data);
          setUploaded((prevState) => ({
            ...prevState,
            [name]: true,
          }));
          setDisabledInputs((prevState) => ({
            ...prevState,
            [name]: true,
          }));
        })
      );
    } else {
      alert('Please select a file first.');
    }
  };

  useEffect(() => {
    const { remitToCountry_lng } = formData || {};
    let id = "1";
    if (remitToCountry_lng && !stateListData[remitToCountry_lng])
      id = remitToCountry_lng;
    // console.log(id,'se id')
    if (id)
      dispatch(
        getStateByCountry(id, authDetails, (data) =>
          setStateListData((state) => ({ 1: data }))
        )
      );
  }, [formData?.remitToCountry_lng]);

  useEffect(() => {
    const params = { Calling_UserID_chr: userId };
    dispatch(
      getCountryList(params, authDetails, (data) => setCountryList(data))
    );
  }, []);

  const onPickupSuggestionSelected = (place) => {
    // console.log(place, "see place id");
    if (place.place_id) {
      dispatch(
        GetAddressByPlaceId(
          { placeId: place.place_id },
          (placeDetails) => {
            const {
              city,
              state: stateName,
              formattedAddress,
              zip,
              country,
            } = getFormattedAddressFromPlaces(placeDetails?.results);

            const stateId = getStateIdFromStateName(stateListData, stateName);

            // console.log(stateId,'what stateid')

            setFormData((state) => ({
              ...state,
              remitToCity_chr: city,
              remitToZipPostalCode_chr: zip,
              remitToStateId_lng: stateId,
              remitToAddressLine1_chr: formattedAddress,
              mailingAddress_chr: formattedAddress,
            }));
          },
          (err) => console.log("error from getGooglePlacesApi", err)
        )
      );
    }
  };

  const onFactoringAddressSuggestionSelected = (place) => {
    console.log(place, "see place id");
    if (place.place_id) {
      dispatch(
        GetAddressByPlaceId(
          { placeId: place.place_id },
          (placeDetails) => {
            const {
              city,
              state: stateName,
              formattedAddress,
              zip,
              country,
            } = getFormattedAddressFromPlaces(placeDetails?.results);

            const stateId = getStateIdFromStateName(stateListData, stateName);

            console.log(stateId, 'what stateid')

            setFormData((state) => ({
              ...state,
              factoringCoCity_chr: city,
              factoringCoZipPostalCode_chr: zip,
              factoringCoStateName_chr: stateName,
              factoringCoStateId_lng: stateId,
              factoringCoAddressLine1_chr: formattedAddress,
            }));
          },
          (err) => console.log("error from getGooglePlacesApi", err)
        )
      );
    }
  };
  console.log(formData, "insaranceFormData123");

  // useEffect(() => {
  //   console.log(formData)
  // }, [formData])

  return (
    <>
      {spinner ? (
        <div id="backdrop">
          <div class="text-center loading">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
      ) : null}
      {linkExpired ? null : (
        <div>
          <form style={{ marginTop: "30px" }}>
            <p
              className="d-flex justify-content-center"
              style={{ color: "#B00000", marginTop: "3%", marginBottom: "2%" }}
            >
              <strong>CARRIER DETAILS</strong>
            </p>

            <div className="form-floating container form-group d-flex flex-row justify-content-between">
              <input
                className="form-control inputInquote"
                placeholder="Carrier Name"
                name="carrierName_chr"
                // onChange={handleform}
                value={formData?.carrierName_chr}
                required
                disabled
                style={{ width: "99%", marginBottom: "20px" }}
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                Carrier Name
              </label>
            </div>
            <div className="form-floating container form-group d-flex flex-row justify-content-between">
              <input
                className="form-control inputInquote"
                placeholder="Address 1"
                name="addressLine1_chr"
                // onChange={handleform}
                value={formData.addressLine1_chr}
                required
                disabled
                style={{ width: "100%", marginBottom: "20px" }}
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                Address 1
              </label>
              <div className="form-floating container ">
                <input
                  className="form-control inputInquote"
                  placeholder="Address 2"
                  name="addressLine2_chr"
                  // onChange={handleform}
                  value={formData.addressLine2_chr}
                  required
                  disabled
                  style={{ width: "100%", marginBottom: "20px" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  Address 2
                </label>
              </div>
            </div>

            <div className="form-floating container  form-group d-flex flex-row justify-content-between">
              <input
                className="form-control inputInquote"
                placeholder="Country"
                name="countryName_chr"
                // onChange={handleform}
                value={formData?.countryName_chr}
                required
                disabled
                style={{ width: "100%", marginBottom: "20px" }}
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                Country
              </label>
              <div class="form-floating container ">
                <input
                  className="form-control inputInquote"
                  placeholder="State"
                  name="stateName_chr"
                  // onChange={handleform}
                  value={formData.stateName_chr}
                  required
                  disabled
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  State
                </label>
              </div>
            </div>
            <div className=" form-floating  container form-group d-flex flex-row justify-content-between">
              <input
                className="form-control inputInquote"
                placeholder="City"
                name="city_chr"
                // onChange={handleform}
                value={formData.city_chr}
                required
                disabled
                style={{ width: "100%" }}
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                City
              </label>
              <div class="form-floating container ">
                <input
                  className="form-control inputInquote2"
                  placeholder="ZipPostalCode"
                  name="zipPostalCode_chr"
                  // onChange={handleform}
                  value={formData.zipPostalCode_chr}
                  required
                  disabled
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  ZipPostalCode
                </label>
              </div>
            </div>

            <p
              className="d-flex justify-content-center"
              style={{ color: "#B00000", marginTop: "3%", marginBottom: "2%" }}
            >
              <strong>CARRIER CONTACT INFORMATION</strong>
            </p>
            <div className="form-floating container mt-3 form-group d-flex flex-row justify-content-between">
              <input
                className="form-control inputInquote"
                placeholder="First name"
                name="firstname"
                // onChange={handleform}
                value={formData?.primaryContactName_chr?.split(" ")[0]}
                required
                style={{ width: "100%" }}
                disabled
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                First Name
              </label>
              <div class="form-floating container ">
                <input
                  className="form-control inputInquote"
                  placeholder="Last name"
                  name="lastname"
                  // onChange={handleform}
                  value={formData?.primaryContactName_chr?.split(" ")[1]}
                  required
                  disabled
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  Last Name
                </label>
              </div>
            </div>
            <div className=" form-floating mt-3 container form-group d-flex flex-row justify-content-between">
              <input
                placeholder="Email"
                className="form-control inputInquote"
                name="primaryContactEmail_chr"
                // onBlur={onBlur}
                // onChange={handleform}
                value={formData?.primaryContactEmail_chr}
                required
                disabled
                style={{ width: "100%" }}
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                Email
              </label>
              <div class="form-floating container ">
                <input
                  placeholder="Phone number"
                  className="form-control inputInquote"
                  name="phoneNumber_chr"
                  // onChange={handleform}
                  // onBlur={onBlur}
                  value={formData?.phoneNumber_chr}
                  required
                  disabled
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  Phone Number
                </label>
              </div>
            </div>

            <div className=" form-floating mt-3 container form-group d-flex flex-column justify-content-between">
              <input
                placeholder="EIN Number"
                className={
                  requiredRedColor.einNumber_chr
                    ? "form-control inputInquoteRequired"
                    : "form-control inputInquote"
                }
                name="einNumber_chr"
                onBlur={handleOnBlur}
                onChange={handleform}
                value={formData?.einNumber_chr}
                required
                style={{ width: "99%" }}
              />
              <label for="floatingInput" style={{ left: "auto" }}>
                EIN Number*
              </label>
              {requiredRedColor.einNumber_chr ? (
                <span className="requiredTheme">Please fill this field</span>
              ) : null}
            </div>

            {/* ----------------------------------- Insurance Details -------------------------------------------------------------- */}
            <p
              className="d-flex justify-content-center"
              style={{ color: "#B00000", marginTop: "4%" }}
            >
              <strong>INSURANCE COMPANY DETAILS</strong>
            </p>

            <br></br>

            <div className="form-floating mt-3 inputdiv container form-group d-flex flex-row justify-content-between">
              <div
                className="form-floating container d-flex flex-column"
                style={{ paddingLeft: "0px" }}
              >
                <input
                  className={
                    requiredRedColor.cargoInsurance_chr
                      ? "form-control inputInquoteRequired"
                      : "form-control inputInquote"
                  }
                  placeholder="Insurance Company"
                  name="cargoInsurance_chr"
                  onChange={handleform}
                  onBlur={handleOnBlur}
                  value={formData?.cargoInsurance_chr}
                  required
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  Insurance Company*
                </label>
                {requiredRedColor.cargoInsurance_chr ? (
                  <span className="requiredTheme">Please fill this field</span>
                ) : null}
              </div>

              <div className="form-floating container">
                <input
                  className={
                    requiredRedColor.insuranceEmail_chr
                      ? "form-control inputInquoteRequired"
                      : "form-control inputInquote"
                  }
                  placeholder="Insurance Email"
                  name="insuranceEmail_chr"
                  onChange={handleform}
                  onBlur={handleOnBlur}
                  value={formData?.insuranceEmail_chr}
                  required
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  Insurance Email*
                </label>
                {requiredRedColor.insuranceEmail_chr ? (
                  <span className="requiredTheme">Please fill this field</span>
                ) : null}
              </div>
            </div>

            <div className="form-floating mt-3 inputdiv container form-group d-flex flex-row justify-content-between">
              <div
                className="form-floating container d-flex flex-column"
                style={{ paddingLeft: "0px" }}
              >
                <input
                  className={
                    requiredRedColor.insuranceContactName_chr
                      ? "form-control inputInquoteRequired"
                      : "form-control inputInquote"
                  }
                  placeholder="Contact name"
                  name="insuranceContactName_chr"
                  onChange={handleform}
                  onBlur={handleOnBlur}
                  value={formData?.insuranceContactName_chr}
                  required
                  style={{ width: "100%" }}
                />
                <label for="floatingInput" style={{ left: "auto" }}>
                  Contact name*
                </label>
                {requiredRedColor.insuranceContactName_chr ? (
                  <span className="requiredTheme">Please fill this field</span>
                ) : null}
              </div>

              <div className="form-floating container">
                <InputMask
                  className={
                    requiredRedColor.insurancePhoneNumber_chr
                      ? "form-control inputInquoteRequired"
                      : "form-control inputInquote"
                  }
                  name="insurancePhoneNumber_chr"
                  value={formData?.insurancePhoneNumber_chr}
                  disabled={false}
                  mask="(999) 999-9999"
                  required
                  onChange={handleform}
                  onBlur={handleOnBlur}
                  placeholder="Phone number "
                  style={{ width: "100%" }}
                >
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>

                <label for="floatingInput" style={{ left: "auto" }}>
                  Phone number*
                </label>
                {requiredRedColor.insurancePhoneNumber_chr ? (
                  <span className="requiredTheme">Please fill this field</span>
                ) : null}
              </div>
            </div>

            <p
              className="d-flex justify-content-center"
              style={{ color: "#B00000", marginTop: "4%" }}
            >
              <strong>FACTORING DETAILS</strong>
            </p>
            <br></br>

            {/* ----------------------------------- Factoring Details -------------------------------------------------------------- */}

            <div className="form-floating inputdiv container  form-group d-flex flex-column justify-content-between">
              <select
                className={
                  requiredRedColor.ownFactoring_chr
                    ? "form-control inputInquoteRequired"
                    : "form-control inputInquote"
                }
                onChange={handleform}
                onBlur={handleOnBlur}
                id="ownFactoring_chr"
                name="ownFactoring_chr"
                style={{ width: "99%" }}
              >
                <option value="">Select </option>
                <option value="yes">YES</option>
                <option value="no">NO</option>
              </select>

              <>
                <label for="ownFactoring_chr" style={{ left: "auto" }}>
                  Do you own a factoring <IoMdArrowDropdown size={22} />
                </label>
                {requiredRedColor.ownFactoring_chr ? (
                  <span className="requiredTheme">
                    Please select this field
                  </span>
                ) : null}
              </>
            </div>
            {disableFactoryOptions === "factory" && (
              <>
                <div className="form-floating inputdiv container form-group d-flex flex-row justify-content-between">
                  <div
                    className="form-floating container d-flex flex-column"
                    style={{ paddingLeft: "0px" }}
                  >
                    <input
                      className={
                        requiredRedColor.factoringCoName_chr
                          ? "form-control inputInquoteRequired"
                          : "form-control inputInquote"
                      }
                      placeholder="Factoring name"
                      name="factoringCoName_chr"
                      onChange={handleform}
                      onBlur={handleOnBlur}
                      required
                      value={formData?.factoringCoName_chr}
                      style={{ width: "100%" }}
                    // disabled={disableFactoryOptions}
                    />
                    <label for="floatingInput" style={{ left: "auto" }}>
                      Factoring Name*
                    </label>
                    {requiredRedColor.factoringCoName_chr ? (
                      <span className="requiredTheme">
                        Please fill this field
                      </span>
                    ) : null}
                  </div>
                  <div class="form-floating container">
                    <input
                      className={
                        requiredRedColor.factoringContactName_chr
                          ? "form-control inputInquoteRequired"
                          : "form-control inputInquote"
                      }
                      placeholder="Factoring contact name"
                      name="factoringContactName_chr"
                      onChange={handleform}
                      onBlur={handleOnBlur}
                      required
                      value={formData?.factoringContactName_chr}
                    // style={{ width: "100%" }}
                    // disabled={disableFactoryOptions}
                    />
                    <label for="floatingInput" style={{ left: "auto" }}>
                      Factoring Contact Name*
                    </label>
                    {requiredRedColor.factoringContactName_chr ? (
                      <span className="requiredTheme">
                        Please fill this field
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="form-floating inputdiv container form-group d-flex flex-row justify-content-between">
                  <div
                    className="form-floating container d-flex flex-column"
                    style={{ paddingLeft: "0px" }}
                  >
                    <InputMask
                      className={
                        requiredRedColor.factoringCoPhoneNumber_chr
                          ? "form-control inputInquoteRequired"
                          : "form-control inputInquote"
                      }
                      name="factoringCoPhoneNumber_chr"
                      value={formData?.factoringCoPhoneNumber_chr}
                      disabled={false}
                      mask="(999) 999-9999"
                      required
                      onChange={handleform}
                      onBlur={handleOnBlur}
                      placeholder="Factoring phone number"
                    >
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                    <label for="floatingInput" style={{ left: "auto" }}>
                      Factoring Phone Number*
                    </label>
                    {requiredRedColor.factoringCoPhoneNumber_chr ? (
                      <span className="requiredTheme">
                        Please fill this field
                      </span>
                    ) : null}
                  </div>

                  <div className="form-floating container">
                    <input
                      className={
                        requiredRedColor.factoringEmail_chr
                          ? "form-control inputInquoteRequired"
                          : "form-control inputInquote"
                      }
                      placeholder="Email"
                      value={formData.factoringEmail_chr}
                      name="factoringEmail_chr"
                      onChange={handleform}
                      onBlur={handleOnBlur}
                      required
                    // style={{ width: "100%" }}
                    />
                    <label for="floatingInput" style={{ left: "auto" }}>
                      Factoring Email*
                    </label>
                    {requiredRedColor.factoringEmail_chr ? (
                      <span className="requiredTheme">
                        Please fill this field
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-floating inputdiv form-group d-flex flex-row justify-content-between">
                  <div className="form-floating container">
                    <PlacesAutocomplete
                      onClickSuggestion={onFactoringAddressSuggestionSelected}
                      className={
                        requiredRedColor.factoringCoAddressLine1_chr
                          ? "form-control inputInquoteRequired"
                          : "form-control inputInquote"
                      }
                      autoComplete={"off"}
                      name="factoringCoAddressLine1_chr"
                      loadsInput={true}
                      required
                      fullWidth
                      placeholder={
                        "Search and Select your Mailing address *"
                      }
                      label="Factoring address *"
                      value={formData.factoringCoAddressLine1_chr}
                      onChange={handleform}
                      handleOnBlur={handleOnBlur}
                    />
                    <label for="floatingInput" style={{ left: "auto" }}>
                      Factoring Address*
                    </label>
                    {requiredRedColor.factoringCoAddressLine1_chr ? (
                      <span className="requiredTheme">
                        Please fill this field
                      </span>
                    ) : null}
                    <p className="align-center mt-1 flex flex-row text-left text-xs text-blue-600">
                      {" "}
                      <FaHandPointRight className="mr-1 text-sm text-blue-600" />
                      You can not enter any random address, please select
                      address from Dropdown only
                    </p>
                  </div>
                </div>


                {/* <div className=" inputdiv container  form-group d-flex flex-row justify-content-between">
                  <label style={{ marginTop: "7px" }}>
                    NOA (Notice of Assignment)*
                  </label>
                  <input
                    type="file"
                    name="NOA"
                    required
                    className={
                      requiredRedColor.NOA
                        ? "form-control inputInquoteRequired"
                        : "form-control inputInquote"
                    }
                    accept="application/pdf"
                    onBlur={handleOnBlur}
                    onChange={handleFileSelect}
                    style={{
                      width: "70%",
                      height: "100%",
                      marginRight: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div> */}

                <div class="container">
                  <div class="row">
                    <div class="col col-sm-5 col-lg-3" style={{ marginTop: "16px" }}>
                      <label style={{ marginTop: "7px" }}>
                        Notice of Assignment*
                      </label>
                    </div>
                    <div class="col col-sm-5 col-lg-7" style={{ marginTop: "16px" }}>
                      <input
                        type="file"
                        name="carrierNoaFile"
                        required
                        className={
                          requiredRedColor.carrierNoaFile
                            ? "form-control inputInquoteRequired"
                            : "form-control inputInquote"
                        }
                        accept="application/pdf"
                        onBlur={handleOnBlur}
                        onChange={handleFileSelect}
                        disabled={disabledInputs.carrierNoaFile}
                        style={{
                          // width: "70%",
                          height: "100%",
                          // marginRight: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </div>
                    <div class="col-2">
                      {uploaded.carrierNoaFile ? (
                        <FaCheck className="text-success" size={24} style={{ marginTop: "16px" }} />
                      ) : (
                        <button
                          style={{ backgroundColor: "#B00000", color: "white", border: "none", borderRadius: "8px", padding: "5px", marginTop: "16px" }}
                          type="button"
                          onClick={() => handleUploadClick('carrierNoaFile')}
                        >
                          <span style={{ margin: "6px" }}>Upload</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {disableFactoryOptions === "remitt" && (
              <>
                <p
                  className="d-flex justify-content-center"
                  style={{ color: "#B00000", marginTop: "4%" }}
                >
                  <strong>REMITTANCE DETAILS</strong>
                </p>
                <br></br>

                <div className="form-floating inputdiv container  form-group d-flex flex-column justify-content-between">
                  <select
                    className={
                      requiredRedColor.achOrCheque_chr
                        ? "form-control inputInquoteRequired"
                        : "form-control inputInquote"
                    }
                    onChange={handleform}
                    onBlur={handleOnBlur}
                    id="achOrCheque_chr"
                    name="achOrCheque_chr"
                  >
                    <option value="">Select</option>
                    <option
                      key={"ach"}
                      // data-id={stateID_ids}
                      value={"ach"}
                    >
                      ACH
                    </option>
                    <option
                      key={"check"}
                      // data-id={stateID_ids}
                      value={"cheque"}
                    >
                      CHECK
                    </option>
                  </select>

                  <label for="floatingInput" style={{ left: "auto" }}>
                    Ach or Check <IoMdArrowDropdown size={22} />
                  </label>
                  {requiredRedColor.achOrCheque_chr ? (
                    <span className="requiredTheme">
                      Please fill this field
                    </span>
                  ) : null}
                </div>
                <div className="form-floating inputdiv container  form-group d-flex flex-column justify-content-between">
                  <select
                    className={
                      requiredRedColor.paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr
                        ? "form-control inputInquoteRequired"
                        : "form-control inputInquote"
                    }
                    onChange={handleform}
                    onBlur={handleOnBlur}
                    id="paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr"
                    name="paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr"
                  >
                    <option value="">Select</option>
                    <option
                      key={"standardPay"}
                      // data-id={stateID_ids}
                      value={"standardPay"}
                    >
                      Standard Pay (30 days)
                    </option>
                    <option
                      key={"quickPay"}
                      // data-id={stateID_ids}
                      value={"quickPay"}
                    >
                      Quick Pay (5% deduction, pay within 7 days)
                    </option>
                  </select>

                  <label for="floatingInput" style={{ left: "auto" }}>
                    Payment Method <IoMdArrowDropdown size={22} />
                  </label>
                  {requiredRedColor.paymentMethod_StandardPay_QuickPay_PayWhenPaid_chr ? (
                    <span className="requiredTheme">
                      Please fill this field
                    </span>
                  ) : null}
                </div>
                {showDetailsOfRemittance === "ach" && (
                  <>
                    <div className="form-floating container form-group d-flex flex-column justify-content-between">
                      <input
                        className={
                          requiredRedColor.financialInstitutionName_chr
                            ? "form-control inputInquoteRequired"
                            : "form-control inputInquote"
                        }
                        placeholder="Financial Institution Name "
                        name="financialInstitutionName_chr"
                        onChange={handleform}
                        onBlur={handleOnBlur}
                        required
                        value={formData?.financialInstitutionName_chr}
                      // style={{ width: "99%" }}
                      // disabled={disableFactoryOptions}
                      />
                      <label for="floatingInput" style={{ left: "auto" }}>
                        Financial Institution Name*
                      </label>
                      {requiredRedColor.financialInstitutionName_chr ? (
                        <span className="requiredTheme">
                          Please fill this field
                        </span>
                      ) : null}
                    </div>

                    <div className="form-floating mt-3 inputdiv container form-group d-flex flex-row justify-content-between">
                      <div
                        className="form-floating container d-flex flex-column"
                        style={{ paddingLeft: "0px" }}
                      >
                        <input
                          className={
                            requiredRedColor.accountNumber_chr
                              ? "form-control inputInquoteRequired"
                              : "form-control inputInquote"
                          }
                          placeholder="Account Number"
                          value={formData.accountNumber_chr}
                          name="accountNumber_chr"
                          onChange={handleform}
                          onBlur={handleOnBlur}
                          required
                        // style={{ width: "100%" }}
                        />
                        <label for="floatingInput" style={{ left: "auto" }}>
                          Account Number*
                        </label>
                        {requiredRedColor.accountNumber_chr ? (
                          <span className="requiredTheme">
                            Please fill this field
                          </span>
                        ) : null}
                      </div>

                      <div className="form-floating container ">
                        <input
                          className={
                            requiredRedColor.routingNumber_chr
                              ? "form-control inputInquoteRequired"
                              : "form-control inputInquote"
                          }
                          placeholder="Routing Number"
                          value={formData.routingNumber_chr}
                          name="routingNumber_chr"
                          onChange={handleform}
                          onBlur={handleOnBlur}
                          required
                        // style={{ width: "100%" }}
                        />
                        <label for="floatingInput" style={{ left: "auto" }}>
                          Routing Number*
                        </label>
                        {requiredRedColor.routingNumber_chr ? (
                          <span className="requiredTheme">
                            Please fill this field
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className=" inputdiv container  form-group d-flex flex-row justify-content-between">
                      <label style={{ marginTop: "7px" }}>Void Check*</label>
                      <input
                        type="file"
                        name="voidCheck"
                        required
                        className={
                          requiredRedColor.voidCheck
                            ? "form-control inputInquoteRequired"
                            : "form-control inputInquote"
                        }
                        accept="image/png, image/jpeg, application/pdf"
                        onBlur={handleOnBlur}
                        onChange={handleVoidCheckFileSelect}
                        style={{
                          width: "70%",
                          height: "100%",
                          marginRight: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </div> */}

                    <div class="container">
                      <div class="row">
                        <div class="col col-sm-5 col-lg-3" style={{ marginTop: "16px" }}>
                          <label >Void Check*</label>
                        </div>
                        <div class="col col-sm-5 col-lg-7" style={{ marginTop: "16px" }}>
                          <input
                            type="file"
                            name="voidCheck"
                            required
                            className={
                              requiredRedColor.voidCheck
                                ? "form-control inputInquoteRequired"
                                : "form-control inputInquote"
                            }
                            accept="image/png, image/jpeg, application/pdf"
                            onBlur={handleOnBlur}
                            // onChange={handleVoidCheckFileSelect}
                            onChange={handleFileSelect}
                            disabled={disabledInputs.voidCheck}
                            style={{
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                        <div class="col-2">
                          {uploaded.voidCheck ? (
                            <FaCheck className="text-success" size={24} style={{ marginTop: "16px" }} />
                          ) : (
                            <button
                              style={{ backgroundColor: "#B00000", color: "white", border: "none", borderRadius: "8px", padding: "5px", marginTop: "16px" }}
                              type="button"
                              onClick={() => handleUploadClick('voidCheck')}
                            >
                              <span style={{ margin: "6px" }}>Upload</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {showDetailsOfRemittance === "cheque" && (
                  <>
                    <div className="form-floating inputdiv container  form-group d-flex flex-row justify-content-between">
                      <div
                        className="form-floating container d-flex flex-column"
                        style={{ paddingLeft: "0px" }}
                      >
                        <input
                          className={
                            requiredRedColor.checksPayableTo_chr
                              ? "form-control inputInquoteRequired"
                              : "form-control inputInquote"
                          }
                          placeholder="Check Payable to"
                          name="checksPayableTo_chr"
                          onChange={handleform}
                          onBlur={handleOnBlur}
                          required
                          value={formData?.checksPayableTo_chr}
                        // style={{ width: "99%" }}
                        // disabled={disableFactoryOptions}
                        />
                        <label for="floatingInput" style={{ left: "auto" }}>
                          Check Payable to*{" "}
                        </label>
                        {requiredRedColor.checksPayableTo_chr ? (
                          <span className="requiredTheme">
                            Please fill this field
                          </span>
                        ) : null}
                      </div>
                      <div className="form-floating container">
                        {/* <Autocomplete> */}
                        <>
                          <select
                            className={
                              requiredRedColor.remitToCountry_lng
                                ? "form-control inputInquoteRequired"
                                : "form-control inputInquote"
                            }
                            placeholder="Mailing country"
                            value={formData.remitToCountry_lng}
                            name="remitToCountry_lng"
                            onChange={handleform}
                            onBlur={handleOnBlur}
                            required
                            style={{ width: "100%" }}
                          >
                            {countryList?.map((res, id) => (
                              <option
                                data-id={res?.countryName_chr}
                                key={id}
                                value={res?.countryID_ids}
                              >
                                {res?.countryName_chr}
                              </option>
                            ))}
                          </select>
                        </>
                        {/* </Autocomplete> */}
                        <label for="floatingInput" style={{ left: "auto" }}>
                          Mailing country*
                        </label>
                        {requiredRedColor.remitToCountry_lng ? (
                          <span className="requiredTheme">
                            Please fill this field
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-floating inputdiv form-group d-flex flex-row justify-content-between">
                      <div className="form-floating container">
                        <PlacesAutocomplete
                          onClickSuggestion={onPickupSuggestionSelected}
                          className={
                            requiredRedColor.mailingAddress_chr
                              ? "form-control inputInquoteRequired"
                              : "form-control inputInquote"
                          }
                          autoComplete={"off"}
                          name="mailingAddress_chr"
                          loadsInput={true}
                          required
                          fullWidth
                          placeholder={
                            "Search and Select your Mailing address *"
                          }
                          label="Mailing address *"
                          value={formData.mailingAddress_chr}
                          onChange={handleform}
                          handleOnBlur={handleOnBlur}
                        />
                        <label for="floatingInput" style={{ left: "auto" }}>
                          Mailing address*
                        </label>
                        {requiredRedColor.mailingAddress_chr ? (
                          <span className="requiredTheme">
                            Please fill this field
                          </span>
                        ) : null}
                        <p className="align-center mt-1 flex flex-row text-left text-xs text-blue-600">
                          {" "}
                          <FaHandPointRight className="mr-1 text-sm text-blue-600" />
                          You can not enter any random address, please select
                          address from Dropdown only
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/* -------------------------------- Document Uplaod Code Start -------------------------------------- */}
            <p
              className="d-flex justify-content-center"
              style={{ color: "#B00000", marginTop: "4%" }}
            >
              <strong>UPLOAD FORMS (PDF Only )</strong>
            </p>
            <br></br>

            {/* {showDetailsOfRemittance === 'ach' && } */}


            <div class="container">
              <div class="row">
                <div class="col col-sm-5 col-lg-3" style={{ marginTop: "16px" }}>
                  <label >W9 form*</label>
                </div>
                <div class="col col-sm-5 col-lg-7" style={{ marginTop: "16px" }}>
                  <input
                    type="file"
                    name="file"
                    required
                    className={
                      requiredRedColor.file
                        ? "form-control inputInquoteRequired"
                        : "form-control inputInquote"
                    }
                    accept="application/pdf"
                    onBlur={handleOnBlur}
                    onChange={handleFileSelect}
                    disabled={disabledInputs.file}
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div class="col-2">
                  {uploaded.file ? (
                    <FaCheck className="text-success" size={24} style={{ marginTop: "16px" }} />
                  ) : (
                    <button
                      style={{ backgroundColor: "#B00000", color: "white", border: "none", borderRadius: "8px", padding: "5px", marginTop: "16px" }}
                      type="button"
                      onClick={() => handleUploadClick('file')}
                    >
                      <span style={{ margin: "6px" }}>Upload</span>
                    </button>
                  )}
                </div>
              </div>
            </div>


            <div class="container">
              <div class="row">
                <div class="col col-sm-5 col-lg-3" style={{ marginTop: "16px" }}>
                  <label>
                    {/* Upload Insurance form - ( pdf only * ) */}
                    Insurance form*
                  </label>
                </div>
                <div class="col col-sm-5 col-lg-7" style={{ marginTop: "16px" }}>
                  <input
                    type="file"
                    name="insuranceForm"
                    className={
                      requiredRedColor.insuranceForm
                        ? "form-control inputInquoteRequired"
                        : "form-control inputInquote"
                    }
                    required
                    onBlur={handleOnBlur}
                    accept="application/pdf"
                    onChange={handleFileSelect}
                    disabled={disabledInputs.insuranceForm}
                    style={{
                      height: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div class="col-2">
                  {uploaded.insuranceForm ? (
                    <FaCheck className="text-success" size={24} style={{ marginTop: "16px" }} />
                  ) : (
                    <button
                      style={{ backgroundColor: "#B00000", color: "white", border: "none", borderRadius: "8px", padding: "5px", marginTop: "16px" }}
                      type="button"
                      onClick={() => handleUploadClick('insuranceForm')}
                    >
                      <span style={{ margin: "6px" }}>Upload</span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col col-sm-5 col-lg-3" style={{ marginTop: "16px" }}>
                  <label>
                    {/* Upload Carrier Authority form - (pdf only *) */}
                    Carrier Authority form*
                  </label>
                </div>
                <div class="col col-sm-5 col-lg-7" style={{ marginTop: "16px" }}>
                  <input
                    type="file"
                    name="carrierAuthorityForm"
                    onBlur={handleOnBlur}
                    className={
                      requiredRedColor.carrierAuthorityForm
                        ? "form-control inputInquoteRequired"
                        : "form-control inputInquote"
                    }
                    required
                    accept="application/pdf"
                    onChange={handleFileSelect}
                    disabled={disabledInputs.carrierAuthorityForm}
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div class="col-2">
                  {uploaded.carrierAuthorityForm ? (
                    <FaCheck className="text-success" size={24} style={{ marginTop: "16px" }} />
                  ) : (
                    <button
                      style={{ backgroundColor: "#B00000", color: "white", border: "none", borderRadius: "8px", padding: "5px", marginTop: "16px" }}
                      type="button"
                      onClick={() => handleUploadClick('carrierAuthorityForm')}
                    >
                      <span style={{ margin: "6px" }}>Upload</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="container" style={{ marginBottom: "10px" }}>
              <button
                // disabled={disableButton}
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
                className="getquotebtn"
                name="Submit"
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default InsuranceDetails;
