// for dev
// export const base_url = "https://tms-dev-api.dialsight.com/";
// export const base_url_common = "https://tms-dev-common-api.dialsight.com/";
// export const domainName = "tms-dev.dialsight.com";
// export const userId = "f62590a4-7afa-4475-96b7-4d1250341b3d";
// export const infoEmailId = "info@dialsight.com"
// export const infoName = "Dialsight TMS"

// for staging
// export const base_url = "https://tms-staging-api.dialsight.com/";
// export const domainName = "tms-staging.dialsight.com";
// export const userId = 'f62590a4-7afa-4475-96b7-4d1250341b3d';
// export const infoEmailId = "info@dialsight.com"
// export const infoName = "Dialsight TMS"

// for production
const tenantName = localStorage.getItem("tenantName");
const base_url = "https://tms-api.dialsight.com/";
export const base_url_common = "https://tms-tenant-api.dialsight.com/";
export const domainName = "tms.dialsight.com";
export const infoEmailId = "info@dialsight.com"
export const infoName = "Dialsight TMS"
export const userId =
  tenantName === "LoadKarma"
    ? "435ad510-043f-401d-8997-5b1ce598cc69"
    : "fde25e60-43f3-4b59-a854-ef430b263efe";

export const GET_SEND_EMAIL = `${base_url}api/EmailService/SendEmail`;
export const POST_SEND_EMAIL = `${base_url}api/EmailService/SendEmailWithAttachment`;
export const GET_CARRIER_LOCATION = `${base_url}api/GetAllCarrier/GetAllCarrierPresence`;
export const GET_FMCSA = `${base_url}api/FMCSA/GetCarrierInfo`;

export const PUT_POST_CARRIER = `${base_url}api/Carrier/PutCarrierDetails`;
export const GET_STATEBY_COUNTRY = `${base_url}api/Master/GetStateByCountry`;
export const GET_CARRIER = `${base_url}api/Carrier/GetCarrierDetails`;
export const POST_CARRIER_DOCS = `${base_url}api/Document/PostPutCarrierDocument`;
export const IS_CARRIER_EXIST = `${base_url}api/Carrier/IsCarrierExist`;
export const GET_TENANT_NAME_BY_ID = `${base_url_common}api/Tenant/GetTenantDataByID`;
export const GET_TENANT_NAME_BY_TENANT_KEY = `${base_url_common}api/Tenant/GetTenantDetailByTenantKey`;
export const GET_DOCUMENT_FILE = `${base_url_common}api/Tenant/GetDocumentFile`;
export const UPLOAD_DOCUMENT = `${base_url}api/Document/PostPutBrokerCarrierAgreementDocument`;
export const GET_DOCUMENT = `${base_url}api/Document/GetCarrierDocument`;
export const GET_DOCUMENT_BLOB = `${base_url}api/Document/GetCarrierDocumentFile`;

export const SAFER_WATCH = `${base_url}api/SaferWatch/GetCarrierInsuranceInfo`;

export const GET_CARRIER_INFO_BY_DOT = `${base_url}api/FMCSA/GetCarrierInfoByDotNumber`;

export const SENDPUSHNOTIFICATION = `${base_url}api/Carrier/SendCarrierNotificationToDeviceGroup`;

export const GET_GOOGLE_PLACES = `${base_url}api/Google/GooglePlaces`;

export const GET_GOOGLE_ADD_BY_PLACEID = `${base_url}api/Google/GetAddressByPlaceId`;

export const GET_ALL_COUNTRY = `${base_url}api/Master/GetCountry`;
