import { combineReducers } from "redux";
import emailServicesReducer from "./emailServices";
import carriersLocationReducer from "./carriersLocation";
import spinnerReducer from "./spinner";
import carrierDocsReducer from "./carrierDocs"

const rootReducer = combineReducers({
  emailServices: emailServicesReducer,
  carriersLocation: carriersLocationReducer,
  spinner:spinnerReducer,
  carrierDocs:carrierDocsReducer
});

export default rootReducer;
