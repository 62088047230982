// import swal from "sweetalert";
import {GET_STATEBY_COUNTRY} from "../redux/types/endPoints"
import {token} from './token';
import axios from "axios";
import { domainName, userId } from '../redux/types/endPoints';

// { id, tenantName, userId, token }
export const getStateByCountry = (data,authDetails, callback, errorCallBack) => { 
    const params = {
        Calling_UserID_chr:authDetails?.userId,
        CountryID_lng:data       
}
// Bearer ${token}
console.log(params,authDetails,'params14');

    return async (dispatch) => {  
    await axios.get(GET_STATEBY_COUNTRY, {params,  headers:{
        'Authorization':"",
        'X_domain_name':authDetails?.tenantName
    }}).then((response) => {         
          callback && callback(response?.data?.refState);    
        })
        .catch((error) => {
          errorCallBack && errorCallBack(error.response);       
        });
    };
  };