// import { alignProperty } from "@mui/material/styles/cssUtils";
// import { textAlign } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import '../../index.css'

import useOnClickOutside from "../../utils/onClickOutside";

const SuggestionListComponent = ({ data, onClick, onClose, renderData }) => {
  const ref = useRef();
  // console.log(renderData, data, "suggestionListComponent");
  useOnClickOutside(ref, () => {
    onClose();
  });
  const [suggestedData, setSuggestedData] = useState([]);
  useEffect(() => {
    setSuggestedData(data);
    return () => {
      data = [];
    };
  }, [data]);

  return (
    <>
      <ul
        ref={ref}
        className="lg:w-100 border border-secondary shadow-md"
        style={{
          maxHeight: "calc(70vh - 100px)", // Adjust the height as needed
          overflowY: "auto",
          paddingLeft:0
          // marginLeft:"20%"
        }}
      >
        {suggestedData &&
          suggestedData.map((item, index) => (
            <li
              className="text-md cursor-pointer p-2 "
              onMouseDown={() => {
                onClick(item);
              }}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  console.error("enter");
                  onClick(item);
                  e.stopPropagation();
                }
              }}
              style={{
                cursor: "pointer",
                fontSize: 15,
                textAlign: "start",
              }}
              key={index}
            >
              {renderData(item)}
            </li>
          ))}
      </ul>
    </>
  );
};

export default SuggestionListComponent;
