import axios from "../../api";
import { SEND_EMAIL } from "../types/emailServices";
import { GET_SEND_EMAIL } from "../types/endPoints";
import { POST_SEND_EMAIL } from "../types/endPoints";
import swal from "sweetalert";
import {token} from '../../api/token';
import { appBarClasses } from "@mui/material";
import { domainName } from '../../redux/types/endPoints';




export const getSendEmail = (params) => async (dispatch) => {
  dispatch({ type: "SET_SPINNER", payload:true })
  try {
    const res = await axios.get(GET_SEND_EMAIL, {
      params,  headers:{
        'Authorization':token,
        'x-domain-name':domainName,
    }
    });
    console.log("ress of send email", res);
    dispatch({ type: "SET_SPINNER", payload:false })
    dispatch({ type: SEND_EMAIL, payload: res?.data });
  } catch (error) {
    console.log('in catch block of send email', error)
    dispatch({ type: "SET_SPINNER", payload:false })
  }
};


export const postSendEmail = (data,authDetails, callback, errorCallBack) => { 
  
  return async (dispatch) => {  
  await axios.post(POST_SEND_EMAIL, data, {
      headers:{
          'Authorization':"",
          "Content-Type": "multipart/form-data",
          'x_domain_name':authDetails?.tenantName,
      }
    }).then((response) => {         
        callback && callback(response);
        console.log('email with atachment response', response)
        
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response);
        swal({
          title: "Error",
          text: "Something Went Wrong",
          icon: "fail",
          dangerMode: true,
        });
      });
  };
};
