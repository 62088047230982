import React from 'react'
import './Footer.css'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as ImIcons from 'react-icons/im'
import * as HiIcons from 'react-icons/hi'
import { NavLink, Link } from 'react-router-dom'
import appstoreimg from './FooterImg/appstoreimg.png'
import playstoreimg from './FooterImg/playstoreimg.png'
export default function Footer() {
  return (
    <>
      <div className=" footerwrapper">
        {/* <div className=" footertop row" style={{ margin: '0px' }}>
          <div className="col-lg-3 footerAbout">
            <h3 className="footerheading pb-2">About Us</h3>
            <p className="footerpara">
              Load Karma is a leading logistics service in Canada and the USA.
              We are committed to meeting all the shipping and trucking needs of
              our clients. We offer 24/7 trucking service and deliver every
              shipment in a timely and safe manner.
            </p>
          </div>

          <div className="col-sm-3 footerUseful">
            <h3 className="footerheading pb-2">Useful Link</h3>

            <ul className="m-0 p-0 footerul">
              <li className="footerlink">
                <NavLink className="footerli" to="/shipper">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  Shipper
                </NavLink>
              </li>

              <li className="footerlink">
                <NavLink className="footerli" to="/about">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  About
                </NavLink>
              </li>

              <li className="footerlink">
                <NavLink className="footerli" to="/carriers">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  Carriers
                </NavLink>
              </li>

              <li className="footerlink">
                <NavLink className="footerli" to="/contact">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  Contact
                </NavLink>
              </li>

              <li className="footerlink">
                <NavLink className="footerli" to="/terms">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  Terms
                </NavLink>
              </li>

              <li className="footerlink">
                <NavLink className="footerli" to="/privacy-policy">
                  <AiIcons.AiFillCaretRight
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'sub',
                      color: '#b00000',
                    }}
                  />
                  Policies
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 footerContact">
            <h3 className="footerheading pb-2">Contact Us</h3>
            <Link className="footerli" to="#">
              <ImIcons.ImLocation
                style={{
                  fontSize: '18px',
                  verticalAlign: 'sub',
                  color: '#b00000',
                }}
              />{' '}
              Address: 3753 HOWARD HUGHES PKWY SUITE 200-803 LAS VEGAS, NV 89169
            </Link>
            <br></br>
            <br></br>

            <Link className="footerli" to="#">
              <FaIcons.FaPhoneAlt
                style={{
                  fontSize: '18px',
                  verticalAlign: 'sub',
                  color: '#b00000',
                }}
              />{' '}
              Phone: (909) 235-9978
            </Link>
            <br></br>
            <br></br>
            <Link className="footerli" to="#">
              <HiIcons.HiOutlineMail
                style={{
                  fontSize: '18px',
                  verticalAlign: 'sub',
                  color: '#b00000',
                }}
              />{' '}
              info@dialsight.com
            </Link>
            <br></br><br></br>
            <p>OnSite Load Karma App</p>
            <a href="https://play.google.com/store/apps/details?id=com.loadkarma.track&hl=en_US&gl=US" target='_blank' rel=" noopener noreferrer">
              <img src={playstoreimg} alt="playstore" style={{width:"40%"}}/>
            </a>
            <a href="https://apps.apple.com/gb/app/onsite-load-karma/id1617010246" target='_blank' rel="noopener noreferrer" >
              <img src={appstoreimg} alt="appstore" style={{width:"40%"}}/>
            </a>
          </div>

          

          <div className="col-lg-2 footerFollow">
            <h3 className="footerheading pb-2">Follow us</h3>
            <Link className="footericon" to="#">
              <FaIcons.FaFacebook style={{ fontSize: '2rem' }} />
            </Link>
            <Link className="footericon" to="#">
              <FaIcons.FaTwitter style={{ fontSize: '2rem' }} />
            </Link>
            <Link className="footericon" to="#">
              <FaIcons.FaInstagram style={{ fontSize: '2rem' }} />
            </Link>
          </div>
        </div> */}
        <hr
          className=""
          style={{
            color: '#58595b',
            backgroundColor: '#58595b',
            height: '2px',
            borderColor: '#58595b',
            opacity: '1',
          }}
        />
        <div className="footerbottom d-flex justify-content-center pb-2">
          <p className="m-0" style={{ color: '#333', fontSize: '11px' }}>
            © 2024 DialSight TMS, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </>
  )
}
