import axios from "../../api";
import { GET_GOOGLE_ADD_BY_PLACEID, GET_GOOGLE_PLACES } from "../types/endPoints";
import {token} from '../../api/token';
import { domainName } from '../../redux/types/endPoints';

export const getGooglePlaces = (params, callback, errorCallBack) => async (dispatch) => {
    try {
      const res = await axios.get(GET_GOOGLE_PLACES, {
        params,  headers:{
          'Authorization':token,
          'x-domain-name':domainName,
      }
      });
      console.log("ress of google places", res);
      callback && callback(res.data);
    } catch (error) {
      console.log('in catch block of google places', error)
      errorCallBack && errorCallBack(error?.response?.data);
    }
  };


  export const GetAddressByPlaceId = (params, callback, errorCallBack) => async (dispatch) => {
    try {
      const res = await axios.get(GET_GOOGLE_ADD_BY_PLACEID, {
        params,  headers:{
          'Authorization':token,
          'x-domain-name':domainName,
      }
      });
      callback && callback(res.data);
    } catch (error) {
        errorCallBack && errorCallBack(error?.response?.data);
    }
  };
